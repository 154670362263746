import { FC, memo } from 'react';

import { ITicket, TicketStatusInfo, CounterStatus } from '@modules/account';

import RenderMembersAndGuests from './components/RenderMembersAndGuests';
import ButtonsComponent from './components/ButtonsComponent';
import CoursesComponent from './components/CoursesComponent';
import DatesComponent from './components/DatesComponent';
import {
  BottomInfoBox,
  InfoBlock,
  InfoBlockRow,
  InfoBlockText,
  InfoBlockTitle,
  Status,
  TicketBox,
  Title,
  TicketHistoryOnly,
} from './styled';
import SeeMoreComponent from './components/ButtonsComponent/components';

interface TicketProps {
  page?: string;
  ticket: ITicket;
  onEdit?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  onReject?: () => void;
  showStatus?: boolean;
  showInterval?: boolean;
  owner?: boolean;
}

const Ticket: FC<TicketProps> = ({
  page = '',
  ticket,
  onEdit,
  onCancel,
  onConfirm,
  onReject,
  showStatus,
  showInterval = true,
  owner = false,
}) => {
  const status = ticket.offerStatus === CounterStatus.REJECTED ? CounterStatus.REJECTED : ticket.status;
  const statusInfo = TicketStatusInfo[status];

  return (
    <TicketBox>
      <div>
        <Title>Ticket</Title>

        <InfoBlockRow>
          <InfoBlock>
            <InfoBlockTitle>Date and Time</InfoBlockTitle>
            <DatesComponent showInterval={showInterval} ticket={ticket} />
          </InfoBlock>

          <InfoBlock>
            <InfoBlockTitle>Courses</InfoBlockTitle>
            <CoursesComponent page={page} ticket={ticket} />
          </InfoBlock>
        </InfoBlockRow>

        <InfoBlockRow>
          <InfoBlock>
            <InfoBlockTitle>Members</InfoBlockTitle>
            <RenderMembersAndGuests ticket={ticket} />
          </InfoBlock>

          {ticket.comment && (
            <InfoBlock>
              <InfoBlockTitle>Comment</InfoBlockTitle>
              <InfoBlockText>{ticket.comment}</InfoBlockText>
            </InfoBlock>
          )}
        </InfoBlockRow>
      </div>
      <ButtonsComponent
        owner={owner}
        page={page}
        ticket={ticket}
        onEdit={onEdit}
        onCancel={onCancel}
        onConfirm={onConfirm}
        onReject={onReject}
      />
      {showStatus && (
        <TicketHistoryOnly>
          <SeeMoreComponent page={page} ticket={ticket} />
          <BottomInfoBox>
            <Status color={statusInfo.color}>{statusInfo.text}</Status>
          </BottomInfoBox>
        </TicketHistoryOnly>
      )}
    </TicketBox>
  );
};

export default memo(Ticket);
