import { useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { IBuddy } from '@modules/book-ticket/types';

import { useGetBuddyListQuery } from '../../../../../index';
import { ListContainer, Title, List, ListItem, SeeAllText } from './styled';

interface BuddyListProps {
  fieldsIds: string[];
  onClick: (buddy: IBuddy) => void;
}

const BuddyList = ({ fieldsIds, onClick }: BuddyListProps) => {
  const { data, isLoading } = useGetBuddyListQuery();
  const buddyList = data?.data?.filter((b) => !fieldsIds.includes(b.id));

  const [isOpen, setIsOpen] = useState(false);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!buddyList) {
    return <div>Missing</div>;
  }

  return (
    <ListContainer>
      <Title>
        Frequently Used Members
      </Title>
      <List
        open={isOpen}
      >
        {
          buddyList?.map((buddy) => {
            const { fullName, number, id } = buddy;

            return (
              <ListItem
                key={id}
                onClick={() => onClick(buddy)}
              >
                {fullName}
                {
                number
                  ? (
                    <svg width="3" height="4" viewBox="0 0 3 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="1.5" cy="2" r="1.5" fill="#252525" />
                    </svg>
                  ) : null
}
                {number ? `ID: ${number}` : ''}
              </ListItem>
            );
          })
        }
      </List>
      <SeeAllText
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {isOpen ? 'Hide' : 'See All'}
      </SeeAllText>
    </ListContainer>
  );
};

export default BuddyList;
