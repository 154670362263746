import React from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { setUser } from '@modules/auth';

import useAlert from '@hooks/useAlert';
import { IEditProfileFormValues } from '../../../../types';
import { useUpdateProfileMutation } from '../../../../api/profile.api';
import { EditProfileFormValidation } from '../../../../helpers/editProfileHelpers';
import { EditProfileContainer, Inputs, SaveButton, StyledTextField } from './styled';

const UpdateName = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(({ auth }) => auth);
  const { showSuccessToast, showErrorToast } = useAlert();

  const [updateProfile] = useUpdateProfileMutation();

  const { handleSubmit, control, formState: { errors } } = useForm<IEditProfileFormValues>({
    defaultValues: {
      fullName: userInfo.member.fullName,
    },
    resolver: yupResolver(EditProfileFormValidation),
  });

  const submitHandler: SubmitHandler<IEditProfileFormValues> = async (data) => {
    try {
      await updateProfile(data).unwrap();

      const payload = {
        ...userInfo,
        member: {
          ...userInfo.member,
          fullName: data.fullName,
        },
      };

      dispatch(setUser(payload));

      showSuccessToast('The profile info has been updated');
    } catch (e) {
      showErrorToast('Something went wrong, try again later');
    }
  };

  return (
    <EditProfileContainer component="form" onSubmit={handleSubmit(submitHandler)}>
      <Inputs>
        <Controller
          name="fullName"
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value,
              name,
            },
          }) => (
            <StyledTextField
              label="Name"
              placeholder="Name"
              type="text"
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors?.fullName?.message}
              helperText={errors?.fullName?.message}
            />
          )}
        />
        <StyledTextField
          disabled
          fullWidth
          label="Email"
          type="email"
          placeholder="Email"
          value={userInfo.user.email}
          InputProps={{
            readOnly: true,
          }}
        />
      </Inputs>

      <SaveButton
        color="success"
        variant="contained"
        type="submit"
      >
        Save changes
      </SaveButton>
    </EditProfileContainer>
  );
};

export default UpdateName;
