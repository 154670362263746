import { Box, css, styled } from '@mui/material';

// TODO: check vh later
export const LayoutContainer = styled(Box)`
  padding: 40px;
  //height: 100vh;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  //align-items: stretch;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 20px;
      min-height: 100vh;
      height: unset;
    }
  `}
`;
