/* eslint-disable no-restricted-imports */
import { TimeZone } from '@constants/time';
import { IGroup } from '@modules/account/types';

import { ICourse, IMember } from '../types';
import { CourseStorageKeys, Steps } from '../constants';
import { savePickCourseDataInStorage } from '../helpers/pickCourseHelpers';
import { saveSelectDateDataInStorage } from '../helpers/selectDateHelpers';
import { saveSelectGroupsDataInStorage } from '../helpers/selectMembersHelpers';
import { saveCommentDataInStorage } from '../helpers/enterCommentHelpers';
import { dayJsTz } from '@utils/dayjs';

export const getInitialActiveStep = () => {
  const activeStep = localStorage.getItem(CourseStorageKeys.ACTIVE_STEP);

  return Number(activeStep) || Steps.COURSE;
};

export const saveActiveStepDataInStorage = (step: Steps) => {
  localStorage.setItem(CourseStorageKeys.ACTIVE_STEP, step.toString());
};

export const clearTicketStorage = () => {
  localStorage.removeItem(CourseStorageKeys.ACTIVE_STEP);
  localStorage.removeItem(CourseStorageKeys.COURSE);
  localStorage.removeItem(CourseStorageKeys.DATE);
  localStorage.removeItem(CourseStorageKeys.MEMBERS);
  localStorage.removeItem(CourseStorageKeys.COMMENT);
  localStorage.removeItem(CourseStorageKeys.GROUPS);
};

export const saveTicketInStorage = (data: {
  courses: ICourse[];
  date: { date: string; interval: number };
  groups: IGroup[];
  comment: string;
}) => {
  const { courses, date, groups, comment } = data;

  const ticketDate = dayJsTz(TimeZone, date.date);

  savePickCourseDataInStorage({ courses });
  saveSelectDateDataInStorage({
    date: ticketDate,
    time: ticketDate.format('hh:mm A'),
    interval: date.interval,
  });

  saveSelectGroupsDataInStorage(groups);
  saveCommentDataInStorage({ text: comment });
};
