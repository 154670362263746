import React from 'react';

import { HeaderContainer, HeaderLogo, HeaderText } from './styled';
import logo from '@assets/images/logo.svg';

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderLogo>
        <img src={logo} alt="tee-swap" />
      </HeaderLogo>

      <HeaderText>
        A universal platform
        for clubs and members to manage reciprocal play.
      </HeaderText>
    </HeaderContainer>
  );
};

export default Header;
