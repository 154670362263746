import React from 'react';

import { LoginForm } from '@modules/auth';
import FooterLinks from '@components/FooterLinks';

const Login = () => {
  return (
    <>
      <LoginForm />
      <FooterLinks />
    </>
  );
};

export default Login;
