import {
  CounterStatus,
  ITicket,
  TicketStatus,
  useChangeTicketStatusMutation,
  useMakeTicketCounterApproveOrDeclineMutation,
} from '@modules/account';
import { saveTicketInStorage } from '@modules/book-ticket';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { PRIVATE_PAGES } from '@app/router/pages';
import { useAppDispatch } from '@app/store';
import { handleOpenTicketsRefetch, setSeeMoreTicket } from '@modules/ticket';

import { getDateFromFirstGroup } from '@utils/time';
import { convertData } from '@utils/dataConverting';
import useAlert from '@hooks/useAlert';
import {
  SeeMoreDialogSubTitle,
  SeeMoreDialogSubWrapper,
} from './styled';

interface localHandlerProps {
  ticket: ITicket | null;
  afterActionShowMessage: (states: TicketStatus) => void;
}

const useLocalLogic = ({
  ticket,
  afterActionShowMessage,
}: localHandlerProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showErrorToast } = useAlert();
  const [makeTicketCounterApproveOrDecline] = useMakeTicketCounterApproveOrDeclineMutation();
  const [changeTicketStatus] = useChangeTicketStatusMutation();

  const clearAdditionalTicket = () => {
    dispatch(setSeeMoreTicket({ ticket: null, page: '' }));
  };

  const renderMembersOfGroup = () => {
    const groups = ticket?.groups || [];

    return groups.map((group, index) => {
      const { members, guests } = group;

      const membersAndGuests = [] as {
        id: string;
        fullName: string;
        isMember: boolean;
      }[];

      members.forEach((member) => {
        membersAndGuests.push({
          id: member.id,
          fullName: member.fullName,
          isMember: true,
        });
      });

      guests.forEach((member) => {
        membersAndGuests.push({
          id: member.id,
          fullName: member.fullName,
          isMember: false,
        });
      });

      return (
        <div key={group.id}>
          <SeeMoreDialogSubTitle>
            <span>
              Group
              {' '}
              {index + 1}
            </span>
          </SeeMoreDialogSubTitle>
          <SeeMoreDialogSubWrapper>
            {
              membersAndGuests.map((item, itemIndex) => {
                return (
                  <div
                    key={item.id}
                    className={item.isMember ? 'member' : 'guest'}
                  >
                    {item.fullName}
                    {
                      membersAndGuests.length === (itemIndex + 1)
                        ? item.isMember ? '(Member)' : '(Guest)'
                        : item.isMember ? '(Member),' : '(Guest),'
                    }
                  </div>
                );
              })
            }
          </SeeMoreDialogSubWrapper>
        </div>
      );
    });
  };

  const handleTicketCancel = async (ticketId: string) => {
    try {
      await changeTicketStatus({
        ticketId,
        status: TicketStatus.CANCELED,
      }).unwrap();

      clearAdditionalTicket();
      afterActionShowMessage(TicketStatus.CANCELED);
      dispatch(handleOpenTicketsRefetch({ fetchState: true }));
    } catch (e) {
      showErrorToast('Something went wrong, try again later');
    }
  };

  const handleTicketEdit = (ticketInfo: ITicket) => {
    const dateFromFirstGroup = getDateFromFirstGroup(ticketInfo.groups);
    const convertedGroups = convertData(ticketInfo.groups);

    saveTicketInStorage({
      courses: ticketInfo?.ticketCourses?.map(({ course }) => course) || [],
      groups: convertedGroups,
      comment: ticketInfo.comment,
      date: {
        date: dateFromFirstGroup.toISOString(),
        interval: ticketInfo.availability,
      },
    });

    clearAdditionalTicket();
    navigate({
      pathname: PRIVATE_PAGES.BOOK_TICKET,
      search: createSearchParams({
        ticketId: ticketInfo.id,
      }).toString(),
    });
  };

  const handleTicketDeclineOrApprove = async ({ status, ticketId, date, ticketCourseId }: {
    date: string;
    ticketId: string;
    status: CounterStatus;
    ticketCourseId: string;
  }) => {
    try {
      await makeTicketCounterApproveOrDecline({
        status,
        ticketId,
        date,
        ticketCourseId,
      }).unwrap();

      if (status === CounterStatus.APPROVED) {
        afterActionShowMessage(TicketStatus.APPROVED);
      }

      if (status === CounterStatus.REJECTED) {
        afterActionShowMessage(TicketStatus.REJECTED);
      }

      clearAdditionalTicket();
      dispatch(handleOpenTicketsRefetch({ fetchState: true }));
    } catch (e) {
      // @ts-ignore
      const errorStatus = e?.data?.errors?.error as string;

      if (!errorStatus) {
        showErrorToast('Something went wrong, try again later');
      }

      dispatch(handleOpenTicketsRefetch({ fetchState: true }));
    }
  };

  return {
    handleTicketCancel,
    handleTicketEdit,
    renderMembersOfGroup,
    handleTicketDeclineOrApprove,
  };
};

export default useLocalLogic;
