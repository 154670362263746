import { MyAccount as Account } from '@modules/account';

const MyAccount = () => {
  return (
    <>
      <Account />
    </>
  );
};

export default MyAccount;
