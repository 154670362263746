import COLORS from '@app/colors';

import { ITicket, TicketStatus } from '../types';

export const TicketStatusInfo = {
  [TicketStatus.APPROVED]: {
    text: 'Approved',
    color: COLORS.green,
  },
  [TicketStatus.PENDING]: {
    text: 'Pending',
    color: '#ff8a03',
  },
  [TicketStatus.DECLINED]: {
    text: 'Declined',
    color: COLORS.red,
  },
  [TicketStatus.CANCELED_APPROVED]: {
    text: 'Canceled',
    color: COLORS.red,
  },
  [TicketStatus.CANCELED]: {
    text: 'Canceled',
    color: COLORS.red,
  },
  [TicketStatus.REJECTED]: {
    text: 'Rejected',
    color: COLORS.red,
  },
};

export const ticketExample: ITicket = {
  id: 'cf13b3b1-e97b-42e3-be09-f2f451566c8d',
  availability: 80,
  status: TicketStatus.APPROVED,
  comment: "Don't touch, for testing",
  date: '2023-04-25T12:00:00.000Z',
  createdAt: '2023-04-13T10:23:47.765Z',
  offerStatus: null,
  ticketCourse: null,
  creator: null,
  groups: [
    {
      id: '3426f150-718b-43bf-b02d-88bcf88305e7',
      ticketId: '12c8cb35-142a-4796-9fe0-a4c8dbc37889',
      number: 1,
      date: '2023-11-30T15:00:13.000Z',
      requestDate: null,
      createdAt: '2023-11-22T15:50:05.998Z',
      updatedAt: '2023-11-22T15:50:05.998Z',
      members: [
        {
          id: '486bc6cf-a6ee-41a9-9f2b-21b38020b333',
          email: 'qeqweee@gmail.com ',
          phone: '37491335303',
          fullName: 'Ditomato',
          number: '37491335303',
        },
      ],
      guests: [
        {
          id: '486bc6cf-a6ee-41a9-9f2b-21b38020bd13',
          groupId: '23452345ydfghsdfgas234',
          deletedAt: null,
          fullName: 'Josh Ditomato',
          isActive: true,
          createdAt: '2023-04-04T16:40:38.704Z',
          updatedAt: '2023-04-04T16:40:38.704Z',
        },
      ],
    },
  ],
  ticketCourses: [
    {
      id: '3f3fecdb-6a18-4428-93e8-3b114c2cf986',
      course: {
        id: '7facbeaa-e0fb-4a53-b26e-71218b2b2c52',
        email: 'lilit.fatyan@brainstormtech.io',
        guestFee: 400,
        memberFee: 300,
        address: '1234 Main Street, Middle',
        name: 'book-ticket by lilith',
        phone: '12345678956',
        isActive: true,
      },
    },
    {
      id: 'ae3fa91c-c9b0-47cd-a663-c2ad84dd6b20',
      course: {
        id: '21b76606-8c15-4a61-ba7f-88530e638ff8',
        email: 'lilit.fatyan@brainstormtech.io',
        guestFee: 2400,
        memberFee: 100,
        address: '1234 Main Street, Middle',
        name: 'testing',
        phone: '12345678956',
        isActive: true,
      },
    },
  ],
};
