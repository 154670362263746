/* eslint-disable no-restricted-imports */
import { IGroup } from '@modules/account/types';

export const convertData = (groups: IGroup[]) => {
  return groups.map((group) => {
    let collectGroup = [] as any[];

    if ('members' in group) {
      const newMembers = (group.members || []).map((g) => ({ isMember: true, id: g.id, fullName: g.fullName }));

      collectGroup = [...collectGroup, ...newMembers];
    }

    if ('guests' in group) {
      const newGuests = (group.guests || []).map((g) => ({ isMember: false, id: g.id, fullName: g.fullName }));

      collectGroup = [...collectGroup, ...newGuests];
    }

    return {
      ...group,
      members: collectGroup,
    };
  });
};
