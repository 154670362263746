import { ICourse, IGroupsPayload } from '../types';

export const stepsToShowStepConnector = [0, 1, 2, 3];

export const getCreateTicketPayload = (
  {
    courses, comment, interval, date, groups }:
    {
      courses: ICourse[];
      groups: IGroupsPayload[];
      date: Date;
      comment: string;
      interval: number;
    },
) => ({
  courses: courses.map(({ id }, index) => ({
    id,
    priority: index + 1,
  })),
  groups,
  date: date.toISOString(),
  comment,
  availability: interval,
});
