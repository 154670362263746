import { css, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ListContainer = styled(Box)`
  height: 100%;
  width: 100%;
  max-width: 624px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
    }
  `}
`;

export const Title = styled(Typography)`
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 500;
  line-height: 18.2px;
  text-align: left;
  margin-bottom: 16px;


  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: unset;
      font-size: 24px;
    }
  `}
`;

export const List = styled(Box)<{ open: boolean }>`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  overflow: hidden;

  ${({ open }) => css`
    height: ${open ? 'auto' : '34px'};
  `}
`;

export const ListItem = styled(Box)`
  cursor: pointer;
  gap: 8px;
  height: 34px;
  line-height: 1;
  border-radius: 8px;
  border: 1px solid #222;
  padding: 8px 12px 8px 12px;

  svg {
    margin: 0 5px 4px 5px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
    }
  `}
`;

export const SeeAllText = styled(Box)`
  cursor: pointer;
  width: max-content;
`;
