import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import useAlert from '@hooks/useAlert';
import { IEditProfilePasswordFormValues } from '../../../../types';
import { useUpdatePasswordMutation } from '../../../../api/profile.api';
import { EditProfilePasswordFormValidation } from '../../../../helpers/editProfileHelpers';
import { EditProfileContainer, Inputs, SaveButton, StyledTextField } from './styled';
import usePasswordInput from '@hooks/usePasswordInput';

const UpdatePassword = () => {
  const { showSuccessToast, showErrorToast } = useAlert();
  const {
    inputType,
    inputProps,
  } = usePasswordInput();

  const [updatePassword] = useUpdatePasswordMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<IEditProfilePasswordFormValues>({
    defaultValues: {
      current: '',
      new: '',
      confirm: '',
    },
    resolver: yupResolver(EditProfilePasswordFormValidation),
  });

  const submitHandler: SubmitHandler<IEditProfilePasswordFormValues> = async (data) => {
    try {
      await updatePassword(data).unwrap();

      reset();
      showSuccessToast('The profile password  has been updated');
    } catch (e) {
      showErrorToast('Something went wrong, try again later');
    }
  };

  return (
    <EditProfileContainer component="form" onSubmit={handleSubmit(submitHandler)}>
      <Inputs>
        <Controller
          name="current"
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value,
              name,
            },
          }) => (
            <StyledTextField
              label="Current Password"
              placeholder="Current Password"
              fullWidth
              name={name}
              value={value}
              type={inputType}
              InputProps={inputProps}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors?.current?.message}
              helperText={errors?.current?.message}
            />
          )}
        />
        <Controller
          name="new"
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value,
              name,
            },
          }) => (
            <StyledTextField
              label="New Password"
              placeholder="New Password"
              type={inputType}
              InputProps={inputProps}
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors?.new?.message}
              helperText={errors?.new?.message}
            />
          )}
        />
        <Controller
          name="confirm"
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value,
              name,
            },
          }) => (
            <StyledTextField
              label="Confirm Password"
              placeholder="Confirm Password"
              type={inputType}
              InputProps={inputProps}
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors?.confirm?.message}
              helperText={errors?.confirm?.message}
            />
          )}
        />
      </Inputs>
      <SaveButton
        color="success"
        variant="contained"
        type="submit"
      >
        Save Password
      </SaveButton>
    </EditProfileContainer>
  );
};

export default UpdatePassword;
