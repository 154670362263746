import { Dispatch, SetStateAction, memo } from 'react';

import { ISelectGroupsFormValues } from '@modules/book-ticket';

import { Container, Line, Wrapper, Item } from './styled';

interface GroupToggleProps {
  active: number;
  setActive: Dispatch<SetStateAction<number>>;
  groups: ISelectGroupsFormValues[];
  onCreate: () => void;
  onDelete: (groupId: string) => void;
}

const GroupToggle = ({ onDelete, active, setActive, groups, onCreate }: GroupToggleProps) => {
  return (
    <Container>
      <Wrapper>
        {groups.map((group, index) => (
          <Item
            key={group.id}
            $isActive={active === index ? 'active' : ''}
          >
            <div onClick={() => setActive(index)}>
              Group
              {' '}
              {index + 1}
            </div>
            {
              active === index &&
              groups.length > 1 &&
              (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  onClick={() => {
                    setActive(0);
                    onDelete(group.id);
                  }}
                >
                  <path d="M11 1L1 11M1 1L11 11" stroke="#047E50" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              )
            }
          </Item>
        ))}
        {
          groups.length < 4 ? (
            <Item
              $isActive=""
              onClick={() => {
                onCreate();
              }}
            >
              + Add Group
            </Item>

          ) : null
        }
      </Wrapper>
      <Line />
    </Container>
  );
};

export default memo(GroupToggle);
