import React from 'react';

const ArrowLeft = () => {
  return (
    <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 1.5L2 7.5M2 7.5L8 13.5M2 7.5H26" stroke="#252525" strokeWidth="1.5" />
    </svg>
  );
};

export default ArrowLeft;
