import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  max-width: 1440px;
  padding: 20px 120px;
  width: 100%;
  margin: 0 auto;

  h1 {
    font-size: 42px
  }

  h2 {
    margin: 20px 0;
    font-size: 32px
  }

  @media (max-width: 920px) {
    padding: 20px 24px;
    
    h1 {
      font-size: 36px
    }

    h2 {
      margin: 20px 0px;
      font-size: 28px
    }
  }
`;
