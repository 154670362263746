import React from 'react';

import { Dashboard } from '@modules/dashboard';

const DashboardPage = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default DashboardPage;
