import { HomeLanding } from '@modules/homeLanding';

const HomeLandingPage = () => {
  return (
    <>
      <HomeLanding />
    </>
  );
};

export default HomeLandingPage;
