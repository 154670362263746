import React, { useState } from 'react';

import { useNavigate } from 'react-router';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import TabPanel, { a11yProps } from '@components/TabPanel';
import ArrowLeft from '@ui/Icons/ArrowLeft';
import COLORS from '@app/colors';
import SuccessDialog from '@components/SuccessDialog';
import FooterLinks from '@components/FooterLinks';
import { useAppDispatch, useAppSelector } from '@app/store';
import SeeMoreDialog from '@components/SeeMoreDialog';
import { setSeeMoreTicket } from '@modules/ticket';
// eslint-disable-next-line no-restricted-imports
import { TicketStatus } from '@modules/account/types';

import EditProfile from '../EditProfile';
import OpenTickets from '../OpenTickets';
import TicketsHistory from '../TicketsHistory';
import { Tab, BackButton, Title, TabsContainer, MyAccountContainer, TabContent } from './styled';
import { mixMessagesHandler, useSmallMessagesLogic } from './useSmallMessagesLogic';

const MyAccount = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useAppDispatch();
  const { ticket: seeMoreTicket, page: pageFromStorage } = useAppSelector((state) => state.additional);

  const handleActiveTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const { showTicketCanceledDialog, setShowTicketCanceledDialog } = useSmallMessagesLogic();

  return (
    <MyAccountContainer>
      <Box>
        <BackButton
          variant="text"
          onClick={() => navigate(-1)}
          startIcon={<ArrowLeft />}
        >
          Back
        </BackButton>

        <Title>My Account</Title>
        <TabsContainer>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              sx: { background: COLORS.green },
            }}
            value={activeTab}
            onChange={handleActiveTabChange}
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Open Tickets" {...a11yProps(1)} />
            <Tab label="Ticket History" {...a11yProps(2)} />
          </Tabs>
        </TabsContainer>
        <TabContent>
          <TabPanel value={activeTab} index={0}>
            <EditProfile />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <OpenTickets />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <TicketsHistory />
          </TabPanel>
        </TabContent>
      </Box>
      <FooterLinks />

      <SeeMoreDialog
        page={pageFromStorage}
        ticket={seeMoreTicket}
        onClose={() => {
          dispatch(setSeeMoreTicket({ ticket: null, page: '' }));
        }}
        afterActionShowMessage={(status: TicketStatus) => {
          mixMessagesHandler({
            status,
            messageSetter: setShowTicketCanceledDialog,
          });
        }}
      />

      <SuccessDialog
        open={!!showTicketCanceledDialog}
        onClose={() => setShowTicketCanceledDialog(null)}
        title={showTicketCanceledDialog?.title || ''}
        description={showTicketCanceledDialog?.description || ''}
      />
    </MyAccountContainer>
  );
};

export default MyAccount;
