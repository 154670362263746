/* eslint-disable react/no-unescaped-entities */
import Typography from '@mui/material/Typography';
import FooterLinks from '@components/FooterLinks';
import Header from '@components/Header';

import { Container } from './styled';

const PrivacyPolicy = () => {
  return (
    <Container className="container">
      <Typography variant="h1">Privacy Policy</Typography>

      <Typography variant="h2">Introduction</Typography>
      <Typography>
        Welcome to Tee Swap LLC, the innovative platform for golf enthusiasts.
        Your privacy is our top priority, and we're committed to safeguarding your information.
        If you have questions about this policy, please contact us at
        {' '}
        <a href="mailto:support@theteeswap.com">support@theteeswap.com</a>
        .
      </Typography>

      <Typography variant="h2">Information Collection</Typography>
      <Typography>
        When you sign up for Tee Swap, we collect your name, address,
        contact information, and payment details. We also gather information from your device,
        like your IP address, operating system, and the specifics of your usage on our platform.
      </Typography>

      <Typography variant="h2">Use of Information</Typography>
      <Typography>
        Your data is crucial in delivering personalized, efficient services.
        We use it for order processing, customer support, security measures, and tailored marketing.
      </Typography>

      <Typography variant="h2">Third-Party Sharing</Typography>
      <Typography>
        We partner with third-party service providers to help us operate effectively.
        These partners, such as payment processors and marketing assistants,
        may have access to your information to perform their services.
      </Typography>

      <Typography>Data Security</Typography>
      <Typography>
        At Tee Swap, we implement stringent security protocols,
        including encryption and secure servers, to prevent unauthorized access to your data.
      </Typography>

      <Typography variant="h2">User Rights</Typography>
      <Typography>
        You have the right to access, correct, or delete your personal data.
        For any concerns about your privacy rights, contact us.
      </Typography>

      <Typography variant="h2">Updates</Typography>
      <Typography>
        We may update this policy periodically. We will notify you of any changes by
        posting the new policy on this page.
      </Typography>

      <Typography variant="h2">Contact Us</Typography>
      <Typography className="contact-info">
        For any inquiries or concerns about this policy, please reach out to our Data Protection Officer at
        {' '}
        <a href="mailto:support@theteeswap.com">support@theteeswap.com</a>
        .
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
