/* eslint-disable react/no-unescaped-entities */
import Typography from '@mui/material/Typography';
import FooterLinks from '@components/FooterLinks';
import Header from '@components/Header';

import { Container } from './styled';

const TermsOfUse = () => {
  return (
    <Container className="container">
      <Typography variant="h1">Terms of Use</Typography>

      <Typography variant="h2">Acceptance of Terms</Typography>
      <Typography>
        Accessing and using Tee Swap signifies your acceptance of these Terms.
        If you disagree, kindly refrain from using our services.
      </Typography>

      <Typography variant="h2">Registration</Typography>
      <Typography>
        You need to register to use our platform. Please provide accurate, up-to-date information during this process.
      </Typography>

      <Typography variant="h2">User Obligations</Typography>
      <Typography>
        Users must utilize Tee Swap responsibly. Any form of misuse,
        including unauthorized copying of our content or infringement of our trademarks, is prohibited.
      </Typography>
    </Container>
  );
};

export default TermsOfUse;
