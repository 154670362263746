import { IEnterCommentFormValues } from '../types';
import { CourseStorageKeys } from '../constants';

export const initialCommentData: IEnterCommentFormValues = {
  text: '',
};

export const getCommentInitialValue = () => {
  const data = localStorage.getItem(CourseStorageKeys.COMMENT);

  return data ? JSON.parse(data) : initialCommentData;
};

export const saveCommentDataInStorage = (values: IEnterCommentFormValues) => {
  localStorage.setItem(CourseStorageKeys.COMMENT, JSON.stringify(values));
};
