import { Box, Button, css, Stack, styled } from '@mui/material';
import TextField from '@ui/TextField';

export const EditProfileContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

    ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
       margin-bottom: 50px;
     }
   `}
`;

export const Inputs = styled(Stack)`
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: unset;
    }
  `}
`;

export const StyledTextField = styled(TextField)`
  width: 300px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const SaveButton = styled(Button)`
  width: 300px;
  margin-top: 60px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;
