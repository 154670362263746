export enum AuthStorageKeys {
  ACCESS_TOKEN = 'accessToken',
  USER = 'user',
  REMEMBER_ME = 'rememberMe',
}

export enum CounterDoneKeys {
  FROM_EMAIL = 'from_email',
  TICKET_ID = 'ticketId',
  STATUS = 'status',
}
