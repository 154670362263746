import React from 'react';

import { SetNewPasswordForm } from '@modules/auth';
import FooterLinks from '@components/FooterLinks';

const SetNewPassword = () => {
  return (
    <>
      <SetNewPasswordForm />

      <FooterLinks />
    </>
  );
};

export default SetNewPassword;
