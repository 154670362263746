import { useMemo, memo } from 'react';

import { ITicket } from '@modules/account';

import { InfoBlockText, GreenColor } from './styled';
import { getDateFromFirstGroup } from '@utils/time';

interface DatesComponentProps {
  ticket: ITicket;
  showInterval: boolean;
}

const DatesComponent = ({ showInterval, ticket }: DatesComponentProps) => {
  const dateInfo = useMemo(() => {
    const date = getDateFromFirstGroup(ticket.groups);

    return {
      date: date.format('MM.DD.YYYY'),
      startTime: date.format('hh:mm A'),
      endTime: date.add(ticket.availability, 'minutes').format('hh:mm A'),
    };
  }, [ticket]);

  return (
    <>
      <InfoBlockText>{dateInfo.date}</InfoBlockText>
      {
        ticket.offerStatus === 'PENDING' && ticket?.ticketCourse !== null ? (
          <InfoBlockText>
            <GreenColor>
              {dateInfo.startTime}
              {' '}
              (Changed)
            </GreenColor>
          </InfoBlockText>
        ) : (
          <InfoBlockText>
            {dateInfo.startTime}
            {showInterval && <>{` - ${dateInfo.endTime}`}</>}
          </InfoBlockText>
        )
      }
    </>
  );
};

export default memo(DatesComponent);
