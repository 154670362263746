import { useAppDispatch, useAppSelector } from '@app/store';
import { logout } from '@modules/auth';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { resetTicketStepper } from 'modules/book-ticket';
import { useNavigate } from 'react-router';
import { PRIVATE_PAGES } from '@app/router/pages';

import logo from '@assets/images/logo.svg';
import { HeaderButton, HeaderContainer, LinksBox, Logo, LogoBox, MiddleBox, Username } from './styled';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { accessToken, userInfo } = useAppSelector(({ auth }) => auth);

  const handleGoHome = () => {
    resetTicketStepper();
    navigate(PRIVATE_PAGES.HOME);
  };

  const handleLogout = () => {
    resetTicketStepper();
    dispatch(logout());
  };

  return (
    <HeaderContainer container>
      <LogoBox item xs={4} lg={4} md={4}>
        <Logo onClick={handleGoHome}>
          <img src={logo} alt="tee-swap" />
        </Logo>
      </LogoBox>

      <MiddleBox xs={12} lg={4} md={2} item id="header-middle" />

      <LinksBox item xs={8} lg={4} md={4}>
        {accessToken && (
          <>
            <Username>{userInfo?.member?.fullName}</Username>
            <Box onClick={handleLogout}>
              {isMobile ? (
                <IconButton>
                  <LogoutIcon />
                </IconButton>
              ) : <HeaderButton variant="outlined">LOG OUT</HeaderButton>}
            </Box>
          </>
        )}
      </LinksBox>
    </HeaderContainer>
  );
};

export default Header;
