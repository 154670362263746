import dot from '@assets/icons/dot.svg';
import { FooterLink, FooterLinksContainer } from './styled';

const FooterLinks = () => {
  return (
    <FooterLinksContainer>
      <FooterLink to="/privacy-policy">PRIVACY POLICY</FooterLink>
      <img src={dot} alt="dot" />
      <FooterLink to="/terms-of-us">TERMS OF USE</FooterLink>
    </FooterLinksContainer>

  );
};

export default FooterLinks;
