import PageTitle from '@ui/PageTitle';
import TextField from '@ui/TextField';
import Button from '@mui/material/Button';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PageSubtitle from '@ui/PageSubtitle';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';

import { Container } from '../styled';
import { useSetNewPasswordMutation } from '../../api/auth.api';
import { ISetNewPasswordFormValues } from '../../types';
import { FormBox, FormInputBox, SuccessBox } from './styled';
import SetNewPasswordFormValidation, { defaultValues } from '../../helpers/setNewPasswordFormValidation';
import useAlert from '@hooks/useAlert';
import usePasswordInput from '@hooks/usePasswordInput';

const SetNewPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { showErrorToast } = useAlert();
  const { inputType, inputProps } = usePasswordInput();

  const {
    inputType: confirmInputType,
    inputProps: confirmInputProps,
  } = usePasswordInput();

  const [setNewPassword, { isLoading, isSuccess }] = useSetNewPasswordMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ISetNewPasswordFormValues>({
    resolver: yupResolver(SetNewPasswordFormValidation),
    defaultValues,
  });

  const onSubmit: SubmitHandler<ISetNewPasswordFormValues> = async (data) => {
    try {
      await setNewPassword({ ...data, token: searchParams.get('token') as string }).unwrap();
    } catch (e) {
      showErrorToast('Something went wrong');
    }
  };

  return (
    <Container>
      {isSuccess ? (
        <SuccessBox>
          <PageTitle textAlign="center">Password changed</PageTitle>
          <PageSubtitle textAlign="center">Your password has been changed successfully.</PageSubtitle>
          <Box
            display="flex"
            justifyContent="center"
            marginTop={4}
          >
            <Button
              type="button"
              sx={{
                maxWidth: 300,
              }}
              fullWidth
              variant="contained"
              color="success"
              onClick={() => navigate('/')}
            >
              Log In
            </Button>
          </Box>
        </SuccessBox>
      ) : (
        <Box>
          <PageTitle textAlign="center">Recover password</PageTitle>
          <PageSubtitle textAlign="center">
            Set the new password for your account so
            {' '}
            <br />
            you can login and access all the features.
          </PageSubtitle>

          <FormBox component="form" onSubmit={handleSubmit(onSubmit)}>
            <FormInputBox>
              <Controller
                name="new"
                control={control}
                render={({
                  field: {
                    onChange,
                    onBlur,
                    value,
                    name,
                  },
                }) => (
                  <TextField
                    label="New password"
                    placeholder="Enter new password"
                    fullWidth
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors?.new?.message}
                    helperText={errors?.new?.message}
                    type={inputType}
                    InputProps={inputProps}
                  />
                )}
              />

              <Controller
                name="confirm"
                control={control}
                render={({
                  field: {
                    onChange,
                    onBlur,
                    value,
                    name,
                  },
                }) => (
                  <TextField
                    label="New password confirmation"
                    placeholder="Enter new password confirmation"
                    fullWidth
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors?.confirm?.message}
                    helperText={errors?.confirm?.message}
                    type={confirmInputType}
                    InputProps={confirmInputProps}
                  />
                )}
              />
            </FormInputBox>

            <Button
              type="submit"
              fullWidth
              disabled={isLoading}
              variant="contained"
              color="success"
            >
              {isLoading ? 'Loading...' : 'Save password'}
            </Button>
          </FormBox>
        </Box>
      ) }
    </Container>
  );
};

export default SetNewPasswordForm;
