import React from 'react';

import { css, stepConnectorClasses, styled, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MuiStepConnector from '@mui/material/StepConnector';
import MuiStepper from '@mui/material/Stepper';
import MuiStep from '@mui/material/Step';
import MuiStepLabel from '@mui/material/StepLabel';
import COLORS from '@app/colors';

export const StepperContainer = styled(Box)`
  padding-top: 46px;
  height: 100%;
  display: flex;
  flex-direction: column;


  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding-top: 50px;
    }
  `}
`;

export const StepperBox = styled(Box)`
  width: 100%;
`;

export const Stepper = styled(MuiStepper)(({ theme }) => ({
  width: 400,
  margin: '0 auto',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StepConnector = styled(MuiStepConnector)(({ theme }) => ({

  [`&.${stepConnectorClasses.root}`]: {
    padding: '0 12px',

    [theme.breakpoints.down('sm')]: {
      padding: '0 6px',
    },
  },
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 'unset',
  },

  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: 'rgba(4, 126, 83, 0.5);',

    [theme.breakpoints.down('sm')]: {
      height: 1,
    },
  },

  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: COLORS.green,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: COLORS.green,
    },
  },

}));

export const Step = styled(MuiStep)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const StepLabel = styled(MuiStepLabel)(() => ({
  height: '100%',
  justifyContent: 'center',

  '& .MuiStepLabel-iconContainer': {
    paddingRight: 0,
  },
}));

const StepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: 'rgba(4, 126, 83, 0.5)',
  width: 9,
  height: 9,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: COLORS.green,
    transform: 'scale(2)',
  }),
  ...(ownerState.completed && {
    backgroundColor: COLORS.green,
  }),
}));

export const StepIcon = ({
  active,
  completed,
  className,
}: { completed?: boolean; active?: boolean; className: string }) => (
  <StepIconRoot
    ownerState={{ completed, active }}
    className={className}
  />
);

export const StepInfo = styled(Typography)`
  font-family: 'Inter', serif;
  font-weight: 400;
  font-size: 15px;
  color: ${COLORS.black};
  text-align: center;
  margin-top: 20px;
`;

export const AutocompleteTextField = styled(TextField)`
  width: 200px;
  
  .MuiInputBase-root {
    background: white;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;
