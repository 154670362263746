import { FC } from 'react';

import { Checkbox as MuiCheckBox, CheckboxProps } from '@mui/material';

const Checkbox: FC<CheckboxProps> = (props) => {
  return (
    <MuiCheckBox {...props} />
  );
};

export default Checkbox;
