import React, { FC, HTMLAttributes, SyntheticEvent, useEffect, useMemo, useState } from 'react';

import PageTitle from '@ui/PageTitle';
import { Box, Typography } from '@mui/material';
import { SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PageSubtitle from '@ui/PageSubtitle';
import Autocomplete from '@mui/material/Autocomplete';
import { useAppSelector } from '@app/store';

import { useGetMembersQuery } from '../../../../api/bookTicket.api';
import StepperButtons from '../StepperButtons';
import { ISelectGroupsFormValues, ISelectMembersFormValues, MemberOptionType } from '../../../../types';
import SelectMembersStep from '../SelectMembersStep';
import { GroupToggle } from './components';
import {
  AutocompleteTitle,
  SelectMembersContainer,
  Selects,
  SelectsRow,
  AutocompleteTextField,
  OptionMenuItem,
  ValidationMessage,
} from './styled';

interface SelectGroupsStepProps {
  defaultValues: ISelectGroupsFormValues[];
  onCreate: () => void;
  onNext: (data: ISelectGroupsFormValues[]) => void;
  onBack: () => void;
  onDelete: (groupId: string) => void;
  onUpdate: (groups: ISelectGroupsFormValues[]) => void;
}

const SelectGroupsStep: FC<SelectGroupsStepProps> = ({
  onUpdate,
  onDelete,
  onCreate,
  onNext,
  onBack,
  defaultValues,
}) => {
  const [active, setActive] = useState<number>(0);
  const [message, setMessage] = useState('');

  const submitHandler = (event: any) => {
    event.preventDefault();

    let isValid = true;

    defaultValues.forEach(({ members }) => {
      const isMemberExist = members?.find((member) => member?.isMember);

      if (!isMemberExist) {
        isValid = false;
      }
    });

    if (isValid) {
      onNext(defaultValues);
      setMessage('');
    } else {
      setMessage('Every group should has member.');
    }
  };

  return (
    <SelectMembersContainer
      component="form"
      onSubmit={submitHandler}
    >
      <PageTitle textAlign="center">Enter your group names</PageTitle>
      <PageSubtitle textAlign="center">Select members, add your guests.</PageSubtitle>

      <GroupToggle
        active={active}
        groups={defaultValues}
        onCreate={onCreate}
        setActive={setActive}
        onDelete={onDelete}
      />
      {message && <ValidationMessage>{message}</ValidationMessage>}
      {
        defaultValues?.map((group, index) => (
          <SelectMembersStep
            onUpdate={onUpdate}
            key={group.id}
            isVisible={active === index ? 'isVisible' : ''}
            groupId={group.id}
            defaultValues={group}
          />
        ))
      }

      <StepperButtons
        backButtonProps={{
          onClick: onBack,
        }}
        nextButtonProps={{
          type: 'submit',
        }}
      />
    </SelectMembersContainer>
  );
};

export default SelectGroupsStep;
