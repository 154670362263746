import React, { useState } from 'react';

import { Box, FormHelperText, Stack, useMediaQuery, useTheme } from '@mui/material';
import COLORS from '@app/colors';
import { Close } from '@mui/icons-material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Description, InfoContentBox, InfoImageBox, InfoRow, Title } from '../Info/styled';
import footer from '@assets/images/home/footer.png';
import {
  Button,
  Copyright,
  Input,
  InputBox,
  SuccessDialog,
  SuccessDialogClose,
  SuccessDialogContent,
  SuccessDialogDescription,
  SuccessDialogTitle,
} from './styled';
import { useMakeRequestMutation } from '../../api/requests.api';

const RequestFormValidation = yup
  .object({
    email: yup.string()
      .email('The Email address is incorrect, please try again')
      .required('Please enter your Email'),
  });

interface RequestFormValues {
  email: string;
}

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<RequestFormValues>({
    resolver: yupResolver(RequestFormValidation),
  });

  const [makeRequest, { isLoading }] = useMakeRequestMutation();

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const onSubmitHandler: SubmitHandler<RequestFormValues> = async (data) => {
    try {
      await makeRequest(data).unwrap();
      setShowSuccessDialog(true);
      reset();
    } catch (e) {
      //
    }
  };

  return (
    <Box>
      <InfoRow>
        <InfoImageBox background={footer} />

        <InfoContentBox
          order={isMobile ? 0 : 1}
          background={COLORS.black}
        >
          <Box>
            <Title>Request a demo</Title>

            <Description>
              Enter your e-mail to receive product updates and
              the latest news from Tee Swap.
            </Description>

            <InputBox component="form" onSubmit={handleSubmit(onSubmitHandler)}>
              <Stack>
                <Input
                  placeholder="Your E-Mail"
                  type="email"
                  {...register('email')}
                />
                <FormHelperText error>{errors?.email?.message}</FormHelperText>
              </Stack>

              <Button
                variant="contained"
                color="success"
                type="submit"
              >
                {isLoading ? 'Wait...' : 'Request'}
              </Button>
            </InputBox>

            <Copyright>
              © Tee Swap, 2023
            </Copyright>
          </Box>
        </InfoContentBox>
      </InfoRow>

      <SuccessDialog
        onClose={() => setShowSuccessDialog(false)}
        open={showSuccessDialog}
      >

        <SuccessDialogContent>
          <SuccessDialogTitle>Request sent</SuccessDialogTitle>
          <SuccessDialogDescription>
            Enter your e-mail to receive product updates and the latest news from Tee Swap.
          </SuccessDialogDescription>

          <SuccessDialogClose
            onClick={() => setShowSuccessDialog(false)}
          >
            <Close fontSize="large" cursor="pointer" />
          </SuccessDialogClose>
        </SuccessDialogContent>
      </SuccessDialog>

    </Box>
  );
};

export default Footer;
