import UpdateName from './components/UpdateName';
import UpdatePassword from './components/UpdatePassword';
import { Wrapper } from './styled';

const EditProfile = () => {
  return (
    <Wrapper>
      <UpdateName />
      <UpdatePassword />
    </Wrapper>
  );
};

export default EditProfile;
