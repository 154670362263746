import { useMemo, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

type InputType = 'text' | 'password'

const usePasswordInput = () => {
  const [inputType, setInputType] = useState<InputType>('password');

  const inputProps = useMemo(() => {
    return {
      endAdornment: inputType === 'text' ? (
        <VisibilityIcon
          cursor="pointer"
          onClick={() => setInputType('password')}
        />
      ) : (
        <VisibilityOffIcon
          cursor="pointer"
          onClick={() => setInputType('text')}
        />
      ),
    };
  }, [inputType]);

  return { inputType, inputProps };
};

export default usePasswordInput;
