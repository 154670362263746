import React, { FC } from 'react';

import PageTitle from '@ui/PageTitle';
import PageSubtitle from '@ui/PageSubtitle';
import { Box } from '@mui/material';
import FooterLinks from '@components/FooterLinks';

import { BackButton, Content, PageSubtitleBox, SuccessStepContainer } from './styled';

interface SuccessStepProps {
  onBack: () => void;
}

const SuccessStep: FC<SuccessStepProps> = ({ onBack }) => {
  return (
    <SuccessStepContainer>
      <Content>
        <PageTitle textAlign="center">Your request is received</PageTitle>
        <PageSubtitleBox>
          <PageSubtitle textAlign="center">
            Thank you, we’ve received your request, a member of the golf
            team will contact you within two days of the requested play date.
          </PageSubtitle>
        </PageSubtitleBox>

        <Box display="flex" justifyContent="center">
          <BackButton variant="contained" color="success" onClick={onBack}>
            Back to Home
          </BackButton>
        </Box>
      </Content>

      <FooterLinks />
    </SuccessStepContainer>
  );
};

export default SuccessStep;
