import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import UiKit from '@pages/UiKit';
import Login from '@pages/Login';
import ResetPassword from '@pages/ResetPassword';
import SetNewPassword from '@pages/SetNewPassword';
import { BookTicket, Dashboard, MyAccount } from '@pages/Auth';
import GuestLayout from '@layouts/GuestLayout';
import AuthLayout from '@layouts/AuthLayout';
import HomeLanding from '@pages/HomeLanding';
import PrivacyPolicy from '@pages/PrivacyPolicy';
import TermsOfUse from '@pages/TermsOfUse';
import { PRIVATE_PAGES, PUBLIC_PAGES } from './pages';
import LayoutUniversal from '@layouts/LayoutUniversal';
import Layout from '@layouts/Layout';

const router = createBrowserRouter([
  {
    path: PUBLIC_PAGES.LANDING,
    element: <HomeLanding />,
  },

  {
    path: '',
    element: <AuthLayout />,
    children: [
      {
        path: PRIVATE_PAGES.HOME,
        element: <Dashboard />,
      },
      {
        path: PRIVATE_PAGES.BOOK_TICKET,
        element: <BookTicket />,
      },
      {
        path: PRIVATE_PAGES.MY_ACCOUNT,
        element: <MyAccount />,
      },
    ],
  },
  {
    path: '',
    element: <LayoutUniversal />,
    children: [
      {
        path: PUBLIC_PAGES.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
      {
        path: PUBLIC_PAGES.TERMS_OF_USE,
        element: <TermsOfUse />,
      },
    ],
  },
  {
    path: '',
    element: <GuestLayout />,
    children: [
      {
        path: PUBLIC_PAGES.UI,
        element: <UiKit />,
      },
      {
        path: PUBLIC_PAGES.LOGIN,
        element: <Login />,
      },
      {
        path: PUBLIC_PAGES.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: PUBLIC_PAGES.SET_NEW_PASSWORD,
        element: <SetNewPassword />,
      },
      {
        path: '*',
        element: <Login />,
      },
    ],
  },
]);

export default router;
