import React from 'react';

import { ResetPasswordForm } from '@modules/auth';
import FooterLinks from '@components/FooterLinks';

const ResetPassword = () => {
  return (
    <>
      <ResetPasswordForm />

      <FooterLinks />
    </>
  );
};

export default ResetPassword;
