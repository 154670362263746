import React, { useState } from 'react';

import Ticket from '@components/Ticket';
import { TicketStatus } from '@modules/account';
import { Pagination, Skeleton } from '@mui/material';
import { PAGINATION_LIMIT } from '@constants/pagination';

import { useGetTicketsQuery } from '../../api/ticket.api';
import { NoTicketsText, PaginationContainer, TicketsBox } from '../OpenTickets/styled';
import { ticketExample } from '../../constants';
import { countOffsetByPage } from '@utils/pagination';

const TicketsHistory = () => {
  const [activePage, setActivePage] = useState(1);

  const { data: ticketsData, isLoading } = useGetTicketsQuery({
    statuses: [TicketStatus.APPROVED, TicketStatus.DECLINED, TicketStatus.CANCELED, TicketStatus.CANCELED_APPROVED],
    sortBy: 'DESC',
    orderBy: 'createdAt',
    limit: PAGINATION_LIMIT,
    offset: countOffsetByPage(activePage),
  });

  if (isLoading) {
    return (
      <TicketsBox>
        {
          [0, 1, 2, 3].map((i) => (
            <Skeleton
              key={i}
              animation="wave"
              variant="rounded"
            >
              <Ticket
                ticket={ticketExample}
                showStatus
              />
            </Skeleton>
          ))
        }
      </TicketsBox>
    );
  }

  return (
    <>
      {ticketsData?.data.length ? (
        <TicketsBox>
          {ticketsData?.data.map((ticket) => (
            <Ticket
              showStatus
              page="ticketHistory"
              key={ticket.id}
              ticket={ticket}
              showInterval={ticket.status !== TicketStatus.APPROVED}
            />
          ))}
        </TicketsBox>
      ) : <NoTicketsText>There are no tickets yet</NoTicketsText>}

      {!!ticketsData?.total && ticketsData.total > PAGINATION_LIMIT && (
        <PaginationContainer>
          <Pagination
            color="success"
            page={activePage}
            count={Math.ceil(ticketsData.total / PAGINATION_LIMIT)}
            onChange={(_, page) => setActivePage(page)}
          />
        </PaginationContainer>
      )}

    </>
  );
};

export default TicketsHistory;
