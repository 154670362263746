/* eslint-disable no-restricted-imports */
import { FC } from 'react';

import Button from '@mui/material/Button';
import { Close } from '@mui/icons-material';
import { CounterStatus, ITicket, TicketStatus } from '@modules/account';
import { useAppSelector } from '@app/store';

import { getDateFromFirstGroup } from '@utils/time';
import useLocalLogic from './useLocalLogic';
import {
  SeeMoreDialogClose,
  SeeMoreDialogContainer,
  SeeMoreDialogContent,
  SeeMoreDialogSubTitle,
  SeeMoreDialogSubWrapper,
  SeeMoreDialogGroupWrapper,
  ButtonsWrapper,
} from './styled';

interface SeeMoreProps {
  page: string;
  ticket: ITicket | null;
  onClose: () => void;
  afterActionShowMessage: (states: TicketStatus) => void;
}

const SeeMoreDialog: FC<SeeMoreProps> = ({ page, ticket, onClose, afterActionShowMessage }) => {
  const { handleTicketEdit, handleTicketCancel, renderMembersOfGroup, handleTicketDeclineOrApprove } = useLocalLogic({
    ticket,
    afterActionShowMessage,
  });

  const auth = useAppSelector((state) => state.auth);

  if (ticket === null) {
    return null;
  }

  const date = getDateFromFirstGroup(ticket.groups);

  const renderCourses = () => {
    if (ticket.status === TicketStatus.APPROVED || ticket.offerStatus === CounterStatus.PENDING) {
      return <span key={ticket?.ticketCourse?.course?.id}>{ticket?.ticketCourse?.course?.name}</span>;
    }

    return ticket?.ticketCourses?.map(({ id, course }, index) => (
      <span key={id}>
        {course?.name}
        {ticket?.ticketCourses.length === index + 1 ? '' : ', '}
      </span>
    ));
  };

  return (
    <SeeMoreDialogContainer onClose={onClose} open={!!ticket}>
      <SeeMoreDialogContent>
        <SeeMoreDialogSubTitle>Date and Time</SeeMoreDialogSubTitle>
        <SeeMoreDialogSubWrapper>
          {date.format('MMM D, YYYY (dddd)')}
          {' '}
          {date.format('hh:mm A')}
          {' - '}
          {date.add(ticket.availability, 'minutes').format('hh:mm A')}
        </SeeMoreDialogSubWrapper>
        <SeeMoreDialogSubTitle>Courses</SeeMoreDialogSubTitle>
        <SeeMoreDialogSubWrapper>{renderCourses()}</SeeMoreDialogSubWrapper>
        {renderMembersOfGroup()}

        {ticket.comment ? (
          <>
            <SeeMoreDialogSubTitle>Comment</SeeMoreDialogSubTitle>
            <SeeMoreDialogSubWrapper>{ticket.comment}</SeeMoreDialogSubWrapper>
          </>
        ) : null}

        <SeeMoreDialogClose onClick={onClose}>
          <Close fontSize="large" cursor="pointer" />
        </SeeMoreDialogClose>
        {page === 'openTickets' ? (
          <ButtonsWrapper>
            {ticket.offerStatus === 'PENDING' ? (
              <>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    handleTicketDeclineOrApprove({
                      ticketCourseId: ticket.id,
                      ticketId: ticket.id,
                      date: ticket.date,
                      status: CounterStatus.APPROVED,
                    });
                  }}
                >
                  Confirm
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleTicketDeclineOrApprove({
                      ticketCourseId: ticket.id,
                      date: ticket?.date,
                      ticketId: ticket.id,
                      status: CounterStatus.REJECTED,
                    });
                  }}
                >
                  Reject
                </Button>
              </>
            ) : (
              !!(auth && ticket.creator && auth.userInfo.user.id === ticket.creator.userId) && (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      handleTicketEdit(ticket);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleTicketCancel(ticket.id);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )
            )}
          </ButtonsWrapper>
        ) : null}
      </SeeMoreDialogContent>
    </SeeMoreDialogContainer>
  );
};

export default SeeMoreDialog;
