import { memo } from 'react';

import { ITicket } from '@modules/account';

import { ActionButtons, BottomInfoBox, Button } from './styled';
import SeeMoreComponent from './components';

interface ButtonsComponentProps {
  page: string;
  ticket: ITicket;
  onConfirm?: () => void;
  onReject?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
  owner?: boolean;
}

const ButtonsComponent = ({ page, ticket, onEdit, onCancel, onConfirm, onReject, owner }: ButtonsComponentProps) => {
  if (ticket.offerStatus === 'PENDING') {
    return onConfirm || onReject ? (
      <BottomInfoBox>
        <SeeMoreComponent page={page} ticket={ticket} />

        <ActionButtons>
          {onConfirm && (
            <Button variant="contained" color="success" onClick={onConfirm}>
              Confirm
            </Button>
          )}
          {onReject && (
            <Button variant="outlined" onClick={onReject}>
              Reject
            </Button>
          )}
        </ActionButtons>
      </BottomInfoBox>
    ) : (
      <></>
    );
  }

  return onEdit || onCancel ? (
    <BottomInfoBox>
      <SeeMoreComponent page={page} ticket={ticket} />

      <ActionButtons>
        {onEdit && owner && (
          <Button variant="contained" color="success" onClick={onEdit}>
            Edit
          </Button>
        )}
        {onCancel && owner && (
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </ActionButtons>
    </BottomInfoBox>
  ) : (
    <></>
  );
};

export default memo(ButtonsComponent);
