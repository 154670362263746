import React from 'react';

import { useNavigate } from 'react-router';
import { PRIVATE_PAGES } from '@app/router/pages';
import FooterLinks from '@components/FooterLinks';
import { useAppSelector } from '@app/store';

import shield from '@assets/icons/shield.svg';
import { DashboardContainer, Title, Button, Footer } from './styled';

const Dashboard = () => {
  const navigate = useNavigate();
  const club = useAppSelector((state) => state.auth.userInfo.club);

  return (
    <DashboardContainer>

      {
        !club?.logo
          ? <img src={shield} alt="shield" />
          : club?.logo.includes('http') || club?.logo.includes('https')
            ? <img src={club.logo} alt="shield" />
            : <img src={`${process.env.REACT_APP_AMAZON_S3_CDN_URL}/${club.logo}`} alt="shield" />
      }

      <Title>Welcome to Tee Swap!</Title>

      <Button
        color="success"
        variant="contained"
        onClick={() => navigate(PRIVATE_PAGES.BOOK_TICKET)}
      >
        Request a Tee Time
      </Button>

      <Button
        variant="outlined"
        onClick={() => navigate(PRIVATE_PAGES.MY_ACCOUNT)}
      >
        My Account
      </Button>

      <Footer>
        <FooterLinks />
      </Footer>
    </DashboardContainer>
  );
};

export default Dashboard;
