import { Box, css, styled } from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {}
  `}
`;
