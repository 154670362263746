import { Box, css, styled } from '@mui/material';
import Button from '@mui/material/Button';

export const StepperButtonsContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      flex-wrap: wrap;
      justify-content: center;
    };
    @media (max-height: 800px) {
      margin-top: 200px;
    }
  `}

  > .MuiBox-root > .MuiBox-root {
      margin-top: auto !important;
  }
`;

const StepperButton = styled(Button)`
  padding-left: 37px;
  padding-right: 37px;
  font-family: 'Inter', serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  
  .MuiButton-endIcon {
    margin-left: 16px;
  }
  .MuiButton-startIcon {
    margin-right: 16px;
  }
`;

export const NextButton = styled(StepperButton)`
  order: 3;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      order: 1;
      width: 100%;
    }
  `}
`;

export const BackButton = styled(StepperButton)`
  order: 1;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      order: 2;
      margin-bottom: 50px;
      width: 100%;
    }
  `}
`;

export const FooterLinks = styled(Box)`
  order: 2;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      order: 3;
    }
  `}
`;
