import { useAppDispatch } from '@app/store';
import { setSeeMoreTicket } from '@modules/ticket';
import { ITicket } from '@modules/account';

import seeMoreArrow from '@assets/icons/arrow-right-alternative.svg';
import { SeeMoreBox } from '../styled';

interface ButtonsComponentProps {
  page: string;
  ticket: ITicket;
}

const SeeMoreComponent = ({ ticket, page }: ButtonsComponentProps) => {
  const dispatch = useAppDispatch();

  return (
    <SeeMoreBox
      onClick={() => {
        dispatch(setSeeMoreTicket({ ticket, page }));
      }}
    >
      See More
      <img src={seeMoreArrow} alt="see more arrow" />
    </SeeMoreBox>
  );
};

export default SeeMoreComponent;
