import Header from '@components/Header';
import { Outlet } from 'react-router';
import FooterLinks from '@components/FooterLinks';

import { LayoutContainer } from '@layouts/styled';
import { useDetectUrlsFromEmail } from '@hooks/useDetectUrlsFromEmail';

const LayoutUniversal = () => {
  useDetectUrlsFromEmail();

  return (
    <LayoutContainer>
      <Header />
      <Outlet />
      <FooterLinks />
    </LayoutContainer>
  );
};

export default LayoutUniversal;
