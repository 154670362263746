import { css, Dialog, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import COLORS from '@app/colors';

export const SuccessDialogContainer = styled(Dialog)`
  & .MuiPaper-root {
    background: transparent;
  }
`;

export const SuccessDialogContent = styled(Box)`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px;
  gap: 17px;
  border-radius: 10px;
  position: relative;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 40px 20px;
    }
  `}
`;

export const SuccessDialogTitle = styled(Typography)`
  font-family: 'Source Serif Pro', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: ${COLORS.green};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: unset;
      font-size: 24px;
    }
  `}
`;

export const SuccessDialogDescription = styled(Typography)`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  text-align: center;
  color: ${COLORS.black};
  max-width: 272px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: unset;
      font-size: 13px;
    }
  `}
`;

export const SuccessDialogClose = styled(Box)`
  position: absolute;
  right: 20px;
  top: 20px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      right: 10px;
      top: 10px;
      
      svg {
        width: 30px;
        height: 30px;
      }
    }
  `}
`;
