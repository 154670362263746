import { TimeZone } from '@constants/time';
import { IGroup } from '@modules/account';

import { dayJsTz } from './dayjs';

export const hourToHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return {
    hours,
    minutes,
  };
};

export const getDateFromFirstGroup = (groups: IGroup[]) => {
  try {
    if (groups && groups[0] && groups[0].date) {
      return dayJsTz(TimeZone, groups[0].date);
    }

    throw new Error('Invalid date format in ticket groups');
  } catch (error: any) {
    console.error('Error parsing date:', error?.message);

    // Handle the error as needed, e.g., return a default date or rethrow the error
    return getDefaultDate();
  }
};

const getDefaultDate = () => {
  // Provide a default date or implement your own default date logic
  return dayJsTz(TimeZone, '2000-01-01');
};
