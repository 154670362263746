import * as yup from 'yup';

export const EditProfileFormValidation = yup
  .object({
    fullName: yup.string().required('Name is required'),
  });

export const EditProfilePasswordFormValidation = yup
  .object({
    current: yup.string().required('Current password is required'),
    new: yup.string().required('New Password is required'),
    confirm: yup.string().required('Confirm Password is required'),
  });
