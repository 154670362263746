import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Input from '@ui/TextField';

const UiKit = () => {
  return (
    <Box>
      <Box marginY={3}>
        <Typography fontWeight="bold">Button</Typography>
        <Button>Login</Button>
      </Box>

      <Box marginY={3}>
        <Typography fontWeight="bold">Input</Typography>
        <Input placeholder="Input placeholder" label="Login" />
      </Box>
    </Box>
  );
};

export default UiKit;
