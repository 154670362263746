import { Box, css, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MuiTab from '@mui/material/Tab';
import COLORS from '@app/colors';

export const MyAccountContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

export const BackButton = styled(Button)`
  margin-bottom: 20px;
  
  svg path {
    stroke: ${COLORS.black}
  }
  
  color: ${COLORS.black}
`;

export const Title = styled(Typography)`
  font-family: 'Source Serif Pro', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.02em;
  color: ${COLORS.green};
  margin-bottom: 10px;

    // ${({ theme }) => css`
  //   ${theme.breakpoints.down('sm')} {
  //     padding-top: 50px;
  //   }
  // `}
`;

export const TabsContainer = styled(Box)`
  border-bottom: 1px solid ${COLORS.silver['50']};
`;

export const Tab = styled(MuiTab)`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 24px 75px;
  color: ${COLORS.silver['50']};
  text-transform: unset;
  
  &.Mui-selected {
    color: ${COLORS.green};
  }

    ${({ theme }) => css`
     ${theme.breakpoints.down('sm')} {
       padding: 10px 20px;
       font-size: 14px;
     }
   `}
`;

export const TabContent = styled(Box)`
  padding-top: 60px;
`;
