import { Box, styled, Button as MuiButton } from '@mui/material';
import COLORS from '@app/colors';

export const SeeMoreBox = styled(Box)`
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0px 20px;
  color: #047E50;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const BottomInfoBox = styled(Box)`
  margin-top: auto;
`;

export const ActionButtons = styled(Box)`
  display: flex;
  gap: 10px;
  padding: 20px;
`;

export const Button = styled(MuiButton)`
  padding: 8px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  
  &.MuiButton-outlined {
    border: 1px solid ${COLORS.silver['50']};
    color: ${COLORS.silver['50']};
  }
`;
