import { FC, useMemo, useState } from 'react';

import PageTitle from '@ui/PageTitle';
import { dayHoursList, DayHourType, TimeZone } from '@constants/time';
import COLORS from '@app/colors';
import Circle from '@ui/Icons/Circle';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import DateOfEventDialog from '@components/DateOfEventDialog';
import { validateIsDateFuture48hours } from '@modules/book-ticket';

import StepperButtons from '../StepperButtons';
import {
  ConfirmStepContainer,
  Content,
  ContentBlock,
  ContentTitle,
  CourseInfo,
  DateInfo,
  Divider,
  MemberName,
  MembersList,
  Text,
  GroupItem,
} from './styled';
import { ICourse, ISelectDateFormValues, ISelectGroupsFormValues, MemberOptionType } from '../../../../types';
import { dayJsTz } from '@utils/dayjs';

interface ConfirmStepProps {
  courses: ICourse[];
  date: ISelectDateFormValues;
  groups: ISelectGroupsFormValues[];
  comment: string;
  loading: boolean;
  onNext: (date: Date) => void;
  onBack: () => void;
  onBackToDatePicker: () => void;
}

const ConfirmStep: FC<ConfirmStepProps> = ({
  courses, date, groups, comment, loading, onNext, onBack, onBackToDatePicker,
}) => {
  const [startDateTime, setStartDateTime] = useState<Date>();
  const [notice, setNotice] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = () => {
    const result = validateIsDateFuture48hours({ selectedDate: date });

    if (result) {
      onNext(startDateTime as Date);

      return;
    }

    setNotice(true);
  };

  const parsedDateInfo = useMemo(() => {
    if (!date.date) {
      return;
    }

    const selectedTime = dayHoursList.find(({ value }) => value === date.time) as DayHourType;

    const startTime = dayJsTz(TimeZone, date.date)
      .set('hours', selectedTime.hours)
      .set('minutes', selectedTime.minutes);

    setStartDateTime(startTime.toDate());

    return {
      date: date.date.format('MMM D, YYYY (dddd)'),
      startTime: startTime.format('hh:mm A'),
      endTime: startTime.add(date.interval, 'minutes').format('hh:mm A'),
    };
  }, [date]);

  return (
    <ConfirmStepContainer>
      <PageTitle textAlign="center">Confirm your reservation request</PageTitle>

      <Content>
        <ContentBlock>
          <ContentTitle>Courses</ContentTitle>
          {courses.map((course, index) => {
            const isRequired = index === 0 || index === 1;
            const nameColor = isRequired ? COLORS.green : '';
            const feeColor = isRequired ? COLORS.green : '#00000080';

            return (
              <CourseInfo key={course.id}>
                <Text color={nameColor}>
                  {course.name}
                </Text>

                {!isMobile && <Circle color={feeColor} />}

                <Text color={feeColor}>
                  {`Member $${course.memberFee}`}
                </Text>

                {!isMobile && <Circle color={feeColor} />}

                <Text color={feeColor}>
                  {`Guest $${course.guestFee}`}
                </Text>
              </CourseInfo>
            );
          })}
        </ContentBlock>

        <Divider />

        {parsedDateInfo && (
          <ContentBlock>
            <ContentTitle>Date and Time</ContentTitle>
            <DateInfo>
              <span>
                {parsedDateInfo.date}
              </span>

              {!isMobile && <Circle />}

              <span>
                {`${parsedDateInfo.startTime} - ${parsedDateInfo.endTime}`}
              </span>

            </DateInfo>
          </ContentBlock>
        )}

        <Divider />

        <ContentBlock>
          <ContentTitle>Invited guests</ContentTitle>
          {groups.map((group, index) => {
            // const names = [...group.members]
            //   .map((item) => ` (${item.isMember ? `${item.fullName} Member` : `${item.fullName} Guest`})`);

            return (
              <GroupItem key={group.id}>
                <Box className="group-name">
                  Group
                  {' '}
                  {index + 1}
                </Box>
                <MembersList>
                  {group.members.map((member, mIndex) => (
                    <Box key={member.id}>
                      <MemberName green={member.isMember}>
                        {member.fullName}
                        {` (${member.isMember ? 'Member' : 'Guest'})`}
                      </MemberName>
                      {(group.members.length - 1) !== mIndex && ', '}
                    </Box>
                  ))}
                </MembersList>
              </GroupItem>
            );
          })}
        </ContentBlock>

        {comment && (
          <>
            <Divider />
            <ContentBlock>
              <ContentTitle>Comment</ContentTitle>
              <div>{comment}</div>
            </ContentBlock>
          </>
        )}
      </Content>
      <DateOfEventDialog
        isButton
        open={notice}
        onClose={() => setNotice(false)}
        title="Date of The Event"
        description="Please select a date that is at least 2 days from now."
        onBackToDatePicker={onBackToDatePicker}

      />
      <StepperButtons
        backButtonProps={{
          onClick: onBack,
        }}
        nextButtonText={loading ? 'Loading, wait...' : 'Confirm'}
        nextButtonProps={{
          onClick: handleSubmit,
          disabled: loading,
        }}
      />
    </ConfirmStepContainer>
  );
};

export default ConfirmStep;
