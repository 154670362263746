import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { authApi, authSlice } from '@modules/auth';
import { helpCenterApi } from '@modules/helpCenter';
import { requestsApi } from '@modules/homeLanding';
import { bookTicketApi } from 'modules/book-ticket';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'redux';
import { NoInfer } from '@reduxjs/toolkit/dist/tsHelpers';
import { ticketApi, profileApi } from '@modules/account';
import { additionalSlice } from '@modules/ticket';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    additional: additionalSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [bookTicketApi.reducerPath]: bookTicketApi.reducer,
    [helpCenterApi.reducerPath]: helpCenterApi.reducer,
    [requestsApi.reducerPath]: requestsApi.reducer,
    [ticketApi.reducerPath]: ticketApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      bookTicketApi.middleware,
      helpCenterApi.middleware,
      ticketApi.middleware,
      requestsApi.middleware,
      profileApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

// For testing
export const setupStore = (preloadedState: PreloadedState<CombinedState<NoInfer<RootState>>>) => {
  return configureStore({
    preloadedState,
    reducer: {
      auth: authSlice.reducer,
      [authApi.reducerPath]: authApi.reducer,
      [bookTicketApi.reducerPath]: bookTicketApi.reducer,
      [helpCenterApi.reducerPath]: helpCenterApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([authApi.middleware, bookTicketApi.middleware, helpCenterApi.middleware]),
  });
};
