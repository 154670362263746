import { BookTicketStepper } from 'modules/book-ticket';

const BookTicket = () => {
  return (
    <>
      <BookTicketStepper />
    </>
  );
};

export default BookTicket;
