import { ICourse, IMember } from 'modules/book-ticket';
import { IPagination } from '@app/types/pagination';

export enum TicketStatus {
  DECLINED = 'DECLINED',
  REJECTED = 'REJECTED', // For counter status
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  CANCELED_APPROVED = 'APPROVED_CANCELED'
}

export enum CounterStatus {
  NO_OFFER = 'NO_OFFER',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  INVALID_APPROVED_TICKET = 'INVALID_APPROVED_TICKET',
  INVALID_REJECTED_TICKET = 'INVALID_REJECTED_TICKET'
}

export type TicketOfferStatusType = null | CounterStatus.NO_OFFER | CounterStatus.PENDING | CounterStatus.REJECTED;

export interface ICreator {
  userId: string;
}

export interface IGroup {
  id: string;
  ticketId: string;
  number: number;
  date: string;
  requestDate: any;
  createdAt: string;
  updatedAt: string;
  members: IMember[];
  guests: {
    id: string;
    groupId: string;
    createdAt: string;
    deletedAt: null;
    fullName: string;
    isActive: boolean;
    updatedAt: string;
  }[];
}

export interface ITicket {
  availability: number;
  comment: string;
  createdAt: string;
  offerStatus: TicketOfferStatusType;
  date: string;
  id: string;
  groups: IGroup[];
  creator: ICreator | null;
  status: TicketStatus;
  ticketCourse: null | { id: string; course: ICourse };
  ticketCourses: Array<{ id: string; course: ICourse }>;
}

export interface IEditProfileFormValues {
  fullName: string;
}

export interface IEditProfilePasswordFormValues {
  current: string;
  new: string;
  confirm: string;
}

export interface IGetTicketsRequest extends IPagination {
  status?: TicketStatus;
  statuses?: TicketStatus[];
  orderBy?: string;
  sortBy?: 'DESC' | 'ASC';
}

export interface IChangeTicketStatusRequest {
  ticketId: string;
  status: TicketStatus;
}
