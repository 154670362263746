import { ITicket } from '@modules/account';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: {
  page: string;
  ticket: ITicket | null;
  refetchOpenTickets: boolean;
} = {
  ticket: null,
  page: '',
  refetchOpenTickets: false,
};

export const additionalSlice = createSlice({
  name: 'additional',
  initialState,
  reducers: {
    setSeeMoreTicket: (state, { payload }: PayloadAction<{ ticket: ITicket | null; page: string }>) => {
      state.ticket = payload.ticket;
      state.page = payload.page;
    },
    handleOpenTicketsRefetch: (state, { payload }: PayloadAction<{ fetchState: boolean }>) => {
      state.refetchOpenTickets = payload.fetchState;
    },
  },
});

export const { setSeeMoreTicket, handleOpenTicketsRefetch } = additionalSlice.actions;
