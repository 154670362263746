import React, { FC, HTMLAttributes, SyntheticEvent, useEffect, useMemo } from 'react';

import PageTitle from '@ui/PageTitle';
import { Box, Grid } from '@mui/material';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import PageSubtitle from '@ui/PageSubtitle';
import Autocomplete from '@mui/material/Autocomplete';
import { StyledSelectTitle } from '@ui/Select/styled';
import { useNavigate } from 'react-router';
import { resetTicketStepper } from '@modules/book-ticket';

import { useGetCoursesQuery } from '../../../../api/bookTicket.api';
import StepperButtons from '../StepperButtons';
import {
  FeeInfo,
  OptionTooltip, OptionTooltipButton,
  OptionTooltipContent,
  OptionTooltipFee,
  OptionTooltipTitle,
  PickCourseContainer,
  ReviewBox,
  ReviewBoxCourseItem,
  ReviewBoxTitle,
  Selects,
  StyledPopper,
  MenuItem, ReviewBoxCourseTitle,
} from './styled';
import { pickCourseFormValidationResolver, savePickCourseDataInStorage } from '../../../../helpers/pickCourseHelpers';
import { ICourse, IPickCourseFormValues } from '../../../../types';
import questionIcon from '@assets/icons/question.svg';
import { range } from '@utils/array';
import { AutocompleteTextField } from '../../styled';

interface PickCourseStepProps {
  defaultValues?: IPickCourseFormValues;
  onNext: (data: IPickCourseFormValues) => void;
}

const isOptionEqualToValue = (a: ICourse, b: ICourse) => a.id === b.id;

const optionRenderer = (props: HTMLAttributes<HTMLLIElement>, { id, name: optionName,
  memberFee, guestFee,
}: ICourse) => (
  <MenuItem
    {...props}
    value={id}
    key={id}
    data-testid="course-option"
  >
    {optionName}
    <OptionTooltip
      onClick={(e) => e.stopPropagation()}
      enterTouchDelay={0}
      title={(
        <OptionTooltipContent>
          <OptionTooltipTitle>Price</OptionTooltipTitle>
          <OptionTooltipFee>{`Member $${memberFee}`}</OptionTooltipFee>
          <OptionTooltipFee>{`Guest $${guestFee}`}</OptionTooltipFee>
        </OptionTooltipContent>
      )}
      placement="top"
    >
      <OptionTooltipButton>
        <img
          src={questionIcon}
          alt="question"
        />
      </OptionTooltipButton>
    </OptionTooltip>
  </MenuItem>
);

const PickCourseStep: FC<PickCourseStepProps> = ({ onNext, defaultValues }) => {
  const navigate = useNavigate();

  const { handleSubmit, control, formState: { errors }, trigger } = useForm<IPickCourseFormValues>({
    resolver: pickCourseFormValidationResolver,
    defaultValues,
    reValidateMode: 'onChange',
  });

  const { fields, remove, update } = useFieldArray({
    control,
    name: 'courses',
    keyName: 'customID',
  });

  const coursesValues = useWatch({
    control,
    name: 'courses',
  });

  const [firstCourse, secondCourse, thirdCourse] = coursesValues;

  const { data: courses } = useGetCoursesQuery();

  const secondCourseOptions = useMemo(() => {
    return courses?.data.filter(({ id }) => id !== firstCourse?.id) || [];
  }, [courses?.data, firstCourse?.id]);

  const thirdCourseOptions = useMemo(() => {
    return secondCourseOptions.filter(({ id }) => id !== secondCourse?.id);
  }, [secondCourseOptions, secondCourse?.id]);

  const fourthCourseOptions = useMemo(() => {
    return thirdCourseOptions.filter(({ id }) => id !== thirdCourse?.id);
  }, [thirdCourseOptions, thirdCourse?.id]);

  const handleSelectChange = (index: number) => (
    event: SyntheticEvent,
    newValue: ICourse | null,
  ) => {
    if (!newValue) {
      return;
    }

    remove(range(index + 1, fields.length));

    update(index, newValue);

    trigger(`courses.${index}`);
  };

  const inputsToRender = useMemo(() => {
    return [
      {
        name: 'firstCourse',
        placeholder: 'Choose Course',
        title: 'First Priority*',
        options: courses?.data as ICourse[],
      },
      {
        name: 'secondCourse',
        placeholder: 'Choose Course',
        title: 'Second Priority*',
        options: secondCourseOptions as ICourse[],
      },
      {
        name: 'thirdCourse',
        placeholder: 'Choose Course',
        title: 'Third Priority',
        options: thirdCourseOptions as ICourse[],
      },
      {
        name: 'fourthCourse',
        placeholder: 'Choose Course',
        title: 'Fourth Priority',
        options: fourthCourseOptions as ICourse[],
      },
    ];
  }, [courses, fourthCourseOptions, secondCourseOptions, thirdCourseOptions]);

  useEffect(() => {
    savePickCourseDataInStorage({ courses: coursesValues });
  }, [coursesValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PickCourseContainer
      component="form"
      onSubmit={handleSubmit(onNext)}
    >
      <PageTitle textAlign="center">Pick 2-4 courses</PageTitle>
      <PageSubtitle textAlign="center">
        In priority order select a
        minimum of 2 courses you are willing to play.
      </PageSubtitle>

      <Box className="courses-wrapper">
        <Selects container>
          {inputsToRender.map((input, index) => {
            const disabled = index !== 0 && !fields[index - 1];

            return (
              <Grid item lg="auto" xs={12} key={input.name}>
                <Box>
                  <StyledSelectTitle>
                    {input.title}
                  </StyledSelectTitle>
                  <Autocomplete
                    data-testid={`course-select-${index}`}
                    aria-disabled={disabled}
                    noOptionsText="No courses"
                    PopperComponent={StyledPopper}
                    value={fields[index] || null}
                    title={input.title}
                    onChange={handleSelectChange(index)}
                    isOptionEqualToValue={isOptionEqualToValue}
                    id={input.title}
                    options={input.options || []}
                    getOptionLabel={(option) => option.name}
                    renderOption={optionRenderer}
                    disabled={disabled}
                    disablePortal
                    renderInput={(params) => (
                      <AutocompleteTextField
                        {...params}
                        error={!!errors?.courses?.[index]?.message}
                        helperText={errors?.courses?.[index]?.message}
                        placeholder={input.placeholder}
                      />
                    )}
                    disableClearable
                  />
                </Box>

              </Grid>
            );
          })}
        </Selects>
      </Box>

      {!!fields.length && (
        <Box>
          <ReviewBox>
            <ReviewBoxTitle>
              Selected courses
            </ReviewBoxTitle>

            {fields.map((item, index) => {
              return (
                <ReviewBoxCourseItem key={item.id}>
                  <Box>
                    <span>{`${index + 1}.`}</span>
                  </Box>
                  <Box>
                    <ReviewBoxCourseTitle>
                      <span>{item.name}</span>
                      <FeeInfo>
                        {`(Member $${item.memberFee}, Guest $${item.guestFee})`}
                      </FeeInfo>
                    </ReviewBoxCourseTitle>
                    {item.notes && <FeeInfo>{item.notes}</FeeInfo>}
                  </Box>
                </ReviewBoxCourseItem>
              );
            })}
          </ReviewBox>
        </Box>
      )}

      <StepperButtons
        backButtonProps={{
          onClick: () => {
            resetTicketStepper();
            navigate(-1);
          },
        }}
        nextButtonProps={{
          type: 'submit',
        }}
      />
    </PickCourseContainer>
  );
};

PickCourseStep.defaultProps = {
  defaultValues: {
    courses: [],
  },
};

export default PickCourseStep;
