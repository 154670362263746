import PageTitle from '@ui/PageTitle';
import TextField from '@ui/TextField';
import Button from '@mui/material/Button';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PageSubtitle from '@ui/PageSubtitle';
import { Box } from '@mui/material';

import { useResetPasswordMutation } from '../../api/auth.api';
import { IResetPasswordFormValues } from '../../types';
import { FormBox, FormInputBox } from './styled';
import ResetPasswordFormValidation, { defaultValues } from '../../helpers/resetPasswordFormValidation';
import useAlert from '@hooks/useAlert';
import { Container, ErrorText } from '../styled';

const ResetPasswordForm = () => {
  const { showSuccessToast } = useAlert();

  const [resetPassword, { isLoading, isError }] = useResetPasswordMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IResetPasswordFormValues>({
    resolver: yupResolver(ResetPasswordFormValidation),
    defaultValues,
  });

  const onSubmit: SubmitHandler<IResetPasswordFormValues> = async (data) => {
    try {
      await resetPassword(data).unwrap();
      showSuccessToast('The link has been sent to your email');
    } catch (e) {
      // showErrorToast('Invalid credentials');
    }
  };

  return (
    <Container>
      <Box>
        <PageTitle textAlign="center">Forgot your password?</PageTitle>
        <PageSubtitle textAlign="center">
          Enter your e-mail address and we’ll
          send you a link to reset your password.
        </PageSubtitle>

        <FormBox component="form" onSubmit={handleSubmit(onSubmit)}>
          <FormInputBox>
            <Controller
              name="email"
              control={control}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value,
                  name,
                },
              }) => (
                <TextField
                  label="Email"
                  placeholder="Email"
                  type="email"
                  fullWidth
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                />
              )}
            />
          </FormInputBox>

          <ErrorText>
            {isError && 'Your credentials are invalid'}
          </ErrorText>

          <Button
            type="submit"
            fullWidth
            disabled={isLoading}
            variant="contained"
            color="success"
          >
            {isLoading ? 'Loading...' : 'Reset password'}
          </Button>
        </FormBox>
      </Box>
    </Container>
  );
};

export default ResetPasswordForm;
