import { Button, Grid, css, styled, Box, Typography } from '@mui/material';
import COLORS from '@app/colors';

export const HeaderContainer = styled(Grid)`
  display: grid;
  min-height: 80px;
  gap: 30px;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;


  > div {
    width: 100%;
    max-width: unset;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      display: grid;
      grid-template-columns: 1fr 2.5fr;
      justify-items: center;
      gap: 30px;
      align-items: center;

      > div {
        &:nth-of-type(1) {
          grid-row: 1 / 1;

        }
        &:nth-of-type(2) {
          grid-row: 2 / 2;
          grid-column: 1 / 3;
        }
      }
    }
  `}
`;

export const LogoBox = styled(Grid)`
  cursor: pointer;
  img {
    width: 145px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      img {
        height: 25px;
      }
    }
  `}
`;

export const Logo = styled(Box)`
  cursor: pointer;
`;

export const MiddleBox = styled(Grid)`
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      order: 3;
      margin-top: 40px;
    }
  `}
`;

export const LinksBox = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};
  order: 3;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      order: 2;
      justify-content: end;
    }
  `}
`;

export const HeaderButton = styled(Button)`
  font-size: 13px;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;

  &.MuiButton-outlined {
    border: 1px solid ${COLORS.silver['50']};
    background: #FFFFFF;
  }

  &.Mui-disabled {
    border-color: silver;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-size: 12px;
      padding: 10px;
    }
  `}
`;

export const Username = styled(Typography)`
  font-size: 15px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-size: 12px;
    }
  `}
`;
