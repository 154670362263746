import { Box, css, styled } from '@mui/material';

export const Container = styled(Box)`
  position: relative;
  margin-top: 55px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
    }
  `}
`;

export const Line = styled(Box)`
  position: absolute;
  width: 100vw;
  height: 1px;
  background: #ddd;
  left: -40px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
    }
  `}
`;

export const Wrapper = styled(Box)`
  width: max-content;
  
  margin: 0 auto;
  display: flex;  
  gap: 20px;
    
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
    max-width: calc(100vw - 40px);
      gap: 40px;
      overflow-x: scroll; 
    }
  `}
`;

export const Item = styled('div') <{ $isActive: 'active' | '' }>`
  min-width: 156px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;

  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  
  ${({ $isActive }) => {
    return $isActive && css`
      border-bottom: 3px solid #047E50;
      color: #047E50;
    `;
  }};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
        min-width: 140px;
        gap: 40px;
        overflow-x: scroll; 
      }
  `}
`;
