import { Box, css, styled, Typography } from '@mui/material';

export const TicketsBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 96px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 30px;
    }
  `}
`;

export const NoTicketsText = styled(Typography)`
  font-size: 30px;
  text-align: center;
`;

export const PaginationContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
