import { useState } from 'react';

import PageTitle from '@ui/PageTitle';
import TextField from '@ui/TextField';
import { Button } from '@mui/material';
import Checkbox from '@ui/Checkbox';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '@app/store';
import { PUBLIC_PAGES } from '@app/router/pages';
import { CourseStorageKeys } from 'modules/book-ticket';
import { CounterDoneKeys } from '@modules/auth';

import { useLazyGetProfileQuery, useLoginMutation } from '../../api/auth.api';
import { setToken, setUser } from '../../store/auth.slice';
import { ILoginFormValues } from '../../types';
import { CheckboxContainer, ForgotPasswordLink, LoginFormBox, LoginFormInputs } from './styled';
import LoginFormValidation, { defaultValues } from '../../helpers/loginFormValidation';
import usePasswordInput from '@hooks/usePasswordInput';
import { Container, ErrorText } from '../styled';

const LoginForm = () => {
  const {
    inputType,
    inputProps,
  } = usePasswordInput();

  const dispatch = useAppDispatch();
  const [rememberMe, setRememberMe] = useState(false);

  const [loginRequest, { isLoading, isError }] = useLoginMutation();
  const [getProfile] = useLazyGetProfileQuery();

  const { handleSubmit, control, formState: { errors } } = useForm<ILoginFormValues>({
    resolver: yupResolver(LoginFormValidation),
    defaultValues,
  });

  const onSubmit: SubmitHandler<ILoginFormValues> = async (data) => {
    try {
      const { data: { accessToken } } = await loginRequest(data).unwrap();

      dispatch(setToken({ accessToken, rememberMe }));

      const { data: profileResponseData } = await getProfile().unwrap();

      dispatch(setUser(profileResponseData));

      localStorage.removeItem(CourseStorageKeys.ACTIVE_STEP);
    } catch (e) {
      //
    }
  };

  return (
    <Container>
      <LoginFormBox component="form" onSubmit={handleSubmit(onSubmit)}>
        <PageTitle textAlign="center">Sign In</PageTitle>

        <LoginFormInputs>
          <Controller
            name="email"
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value,
                name,
              },
            }) => (
              <TextField
                label="Email"
                placeholder="Email"
                type="email"
                fullWidth
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({
              field: {
                onChange,
                onBlur,
                value,
              },
            }) => (
              <TextField
                label="Password"
                placeholder="Password"
                type={inputType}
                InputProps={inputProps}
                fullWidth
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors?.password?.message}
                helperText={errors?.password?.message}
              />
            )}
          />
        </LoginFormInputs>

        <CheckboxContainer onClick={() => setRememberMe((checked) => !checked)}>
          <Checkbox checked={rememberMe} />
          Remember me
        </CheckboxContainer>

        <ErrorText>
          {isError && 'Your credentials are invalid'}
        </ErrorText>

        <Button
          type="submit"
          fullWidth
          disabled={isLoading}
          variant="contained"
          color="success"
        >
          {isLoading ? 'Loading...' : 'Login'}
        </Button>

        <ForgotPasswordLink to={PUBLIC_PAGES.RESET_PASSWORD}>Forgot password?</ForgotPasswordLink>

      </LoginFormBox>
    </Container>
  );
};

export default LoginForm;
