import { Stack, styled, Typography, Button as MuiButton, Box, css } from '@mui/material';
import COLORS from '@app/colors';

export const DashboardContainer = styled(Stack)`
  align-items: center;
  padding-top: 90px;
  flex-grow: 1;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
        padding-top: 20px;
    }
  `}

  > img {
    width: 200px;
  }
`;

export const Title = styled(Typography)`
  font-family: 'Source Serif Pro', serif;
  font-weight: 700;
  font-size: 48px;
  color: ${COLORS.green};
  text-align: center;
  margin: 60px 0;
`;

export const Button = styled(MuiButton)`
  min-width: 280px;
  margin-top: 20px;
`;

export const Footer = styled(Box)`
  margin-top: auto;
`;
