import queryString from 'query-string';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';

import { IChangeTicketStatusRequest, IGetTicketsRequest, ITicket } from '../types';

export const ticketApi = createApi({
  reducerPath: 'ticket',
  keepUnusedDataFor: 0,
  baseQuery: buildBaseQuery(''),
  endpoints: (builder) => ({
    getTickets: builder.query<{ data: ITicket[]; total: number }, IGetTicketsRequest>({
      query: (params) => {
        const queryParams = queryString.stringify(params, { arrayFormat: 'bracket' });

        return {
          url: `/tickets?${queryParams}`,
          method: 'GET',
        };
      },
    }),
    changeTicketStatus: builder.mutation<void, IChangeTicketStatusRequest>({
      query: ({ ticketId, status }) => ({
        url: `/tickets/${ticketId}/status`,
        method: 'PUT',
        body: { status },
      }),
    }),
    makeTicketCounterApproveOrDecline: builder.mutation<void, any>({
      query: ({ ticketId, status, date, ticketCourseId }) => ({
        url: `/tickets/${ticketId}/resp-counter`,
        method: 'PUT',
        body: {
          status,
          // date,
          // ticketCourseId,
        },
      }),
    }),
  }),

});

export const {
  useGetTicketsQuery,
  useLazyGetTicketsQuery,
  useChangeTicketStatusMutation,
  useMakeTicketCounterApproveOrDeclineMutation,
} = ticketApi;
