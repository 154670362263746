import { AuthStorageKeys } from '../constants';
import { IAuthStore, IClub, IMember, IUser } from '../types';

const getUserDataStorageType = () => {
  const rememberMe = localStorage.getItem(AuthStorageKeys.REMEMBER_ME);

  return rememberMe ? localStorage : sessionStorage;
};

export const getAuthInitialState = (): IAuthStore => {
  const storage = getUserDataStorageType();

  return {
    accessToken: storage.getItem(AuthStorageKeys.ACCESS_TOKEN) || '',
    userInfo: JSON.parse(storage.getItem(AuthStorageKeys.USER) as string) || {},
  };
};

export const saveUserDataInStorage = (payload: { user?: IUser; member?: IMember; club?: IClub }) => {
  const storage = getUserDataStorageType();

  storage.setItem(AuthStorageKeys.USER, JSON.stringify({
    user: payload.user,
    member: payload.member,
    club: payload.club,
  }));
};

export const saveTokenInStorage = (accessToken: string) => {
  const storage = getUserDataStorageType();

  storage.setItem(AuthStorageKeys.ACCESS_TOKEN, accessToken);
};

export const purgeAuthDataFromStorage = () => {
  const storage = getUserDataStorageType();

  storage.removeItem(AuthStorageKeys.ACCESS_TOKEN);
  storage.removeItem(AuthStorageKeys.USER);
  localStorage.removeItem(AuthStorageKeys.REMEMBER_ME);
};
