import React, { FC } from 'react';

import { Close } from '@mui/icons-material';

import {
  SuccessDialogClose,
  SuccessDialogContainer,
  SuccessDialogContent,
  SuccessDialogDescription,
  SuccessDialogTitle,
} from './styled';

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
}

const SuccessDialog: FC<SuccessDialogProps> = ({
  open,
  onClose,
  title,
  description,
}) => {
  return (
    <SuccessDialogContainer
      onClose={onClose}
      open={open}
    >
      <SuccessDialogContent>
        <SuccessDialogTitle>{title}</SuccessDialogTitle>
        <SuccessDialogDescription>
          {description}
        </SuccessDialogDescription>

        <SuccessDialogClose
          onClick={onClose}
        >
          <Close fontSize="large" cursor="pointer" />
        </SuccessDialogClose>
      </SuccessDialogContent>
    </SuccessDialogContainer>
  );
};

export default SuccessDialog;
