import { ITicket } from '@modules/account';

import {
  InfoBlockTextDots,
  MembersWrapper,
} from './styled';

const RenderMembersAndGuests = ({ ticket }: { ticket: ITicket }) => {
  const groups = ticket?.groups?.slice(0, 2) || [];
  const reversed = groups;

  return (
    <>
      {
        reversed.map((group, index) => {
          const { members, guests } = group;

          const names = [...members, ...guests].map((item) => item.fullName);

          return (
            <MembersWrapper key={group.id}>
              <span>
                Group
                {' '}
                {index + 1}
              </span>
              <InfoBlockTextDots>
                {names.join(', ')}
              </InfoBlockTextDots>
            </MembersWrapper>
          );
        })
      }
    </>
  );
};

export default RenderMembersAndGuests;
