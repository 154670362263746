import { authSlice } from './store/auth.slice';
import { authApi, useGetProfileQuery } from './api/auth.api';
import LoginForm from './components/LoginForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import SetNewPasswordForm from './components/SetNewPasswordForm';
import { CounterDoneKeys, AuthStorageKeys } from './constants';

export const { logout, setUser } = authSlice.actions;

export {
  CounterDoneKeys,
  AuthStorageKeys,
  LoginForm,
  ResetPasswordForm,
  SetNewPasswordForm,
  authApi,
  useGetProfileQuery,
  authSlice,
};
