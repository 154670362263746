import { FC, useEffect, useMemo, useState } from 'react';

import { Box, FormHelperText, MenuItem, SelectChangeEvent } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PageTitle from '@ui/PageTitle';
import { DayHourType, TimeZone } from '@constants/time';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import PageSubtitle from '@ui/PageSubtitle';
import { yupResolver } from '@hookform/resolvers/yup';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DateOfEventDialog from '@components/DateOfEventDialog';
import { useAppSelector } from '@app/store';

import {
  CalendarBox,
  CalendarPicker,
  DateContainer,
  RangeBox,
  RangeBoxContent,
  RangeBoxContentTitle,
  RangeBoxTitle,
  Slider,
  SliderBox,
  SubtitleBox,
  TimeSelect,
} from './styled';
import StepperButtons from '../StepperButtons';
import { ISelectDateFormValues } from '../../../../types';
import {
  saveSelectDateDataInStorage,
  selectDateDayHours,
  SelectDateFormValidation,
  selectDateSliderMarks,
  validateIsDateFuture48hours,
  valueLabelFormat,
} from '../../../../helpers/selectDateHelpers';
import { dayJsTz } from '@utils/dayjs';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(TimeZone);

const minDate = dayJsTz(TimeZone).startOf('days').add(2, 'day');
// const minDate = dayJsTz(TimeZone).add(48, 'hours');

interface SelectDateStepProps {
  defaultValues?: ISelectDateFormValues;
  onNext: (data: ISelectDateFormValues) => void;
  onBack: () => void;
}

const SelectDateStep: FC<SelectDateStepProps> = ({
  defaultValues,
  onNext,
  onBack,
}) => {
  const [notice, setNotice] = useState(false);
  const { setValue, control, handleSubmit, formState: { errors } } = useForm<ISelectDateFormValues>({
    defaultValues,
    resolver: yupResolver(SelectDateFormValidation),
  });

  const authData = useAppSelector(({ auth }) => auth);
  const { date, time, interval } = useWatch({
    control,
  });

  const finishTime = useMemo(() => {
    if (!interval || !time) {
      return '';
    }

    const selectedTime = selectDateDayHours.find(({ value }) => value === time) as DayHourType;

    return dayJsTz(TimeZone)
      .set('hours', selectedTime.hours)
      .set('minutes', selectedTime.minutes)
      .add(interval, 'minutes')
      .format('hh:mm A');
  }, [interval, time]);

  // const finishTime = useMemo(() => {
  //   if (!interval || !time) {
  //     return '';
  //   }

  //   const splitDone = time.split(':');
  //   const hour = splitDone[0];
  //   let selectedTime: DayHourType;

  //   if (hour) {
  //     selectedTime = selectDateDayHours.find(({ value }) => value.includes(hour)) as DayHourType;
  //   } else {
  //     selectedTime = selectDateDayHours.find(({ value }) => value === time) as DayHourType;
  //   }

  //   return dayJsTz(TimeZone)
  //     .set('hours', selectedTime.hours)
  //     .set('minutes', selectedTime.minutes)
  //     .add(interval, 'minutes')
  //     .format('hh:mm A');
  // }, [interval, time]);

  const submitHandler: SubmitHandler<ISelectDateFormValues> = (data) => {
    const result = validateIsDateFuture48hours({ selectedDate: data });

    if (result) {
      onNext(data);

      return;
    }

    setNotice(true);
  };

  const handleTimeChange = (event: SelectChangeEvent) => {
    setValue('time', event.target.value, {
      shouldValidate: true,
    });
  };

  const timeOptions = useMemo(() =>
    selectDateDayHours.map((option) => {
      // const selectedDate = dayJsTz(TimeZone, date as Date || new Date()).startOf('days');
      // const then2Day = dayJsTz(TimeZone).startOf('days').add(2, 'day');
      // const optionDate = selectedDate.set('hours', option.hours).set('minutes', option.minutes);
      // const result = then2Day.diff(optionDate, 'hours', true);
      // return { ...option, disabled: !!(result > 0) };

      return {
        ...option, disabled: false,
      };
    }), [date]);

  useEffect(() => {
    saveSelectDateDataInStorage({
      date: date as Dayjs,
      time: time as string,
      interval: interval as number,
    });
  }, [date, time, interval]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // users in live
  const CLUBS_IN_LIVE = [
    '9b2ceb1b-6304-4345-8513-d5a43f82e662', // Seagate
  ];

  // calendar flow Exceptions
  const isInExceptionsClubs = CLUBS_IN_LIVE.includes(authData?.userInfo?.club?.id || '');

  return (
    <DateContainer component="form" onSubmit={handleSubmit(submitHandler)}>
      <PageTitle textAlign="center">What time do you want to play?</PageTitle>
      <SubtitleBox>
        <PageSubtitle textAlign="center">
          Select the date and time for your T time reservation. Please select a window so we don’t have to call you back
          to confirm additional times. If you are available for 9 - 11am select 9am as your priority with a 2 hour
          availability window.
        </PageSubtitle>
      </SubtitleBox>

      <CalendarBox>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CalendarPicker
              data-testid="calendar"
              disablePast
              minDate={minDate}
              maxDate={isInExceptionsClubs ? minDate.add(30, 'day') : minDate.add(10, 'day')}
              dayOfWeekFormatter={(day) => day.toUpperCase()}
              showDaysOutsideCurrentMonth
              date={date}
              onChange={(newDate) => {
                setValue('date', newDate as Dayjs, {
                  shouldValidate: !time,
                });
                setValue('time', '');
              }}
            />
          </LocalizationProvider>
          <FormHelperText error>
            {errors?.date?.message}
          </FormHelperText>
        </Box>
        <Box>
          <TimeSelect
            data-testid="time-select"
            title="Start time"
            label="Choose time"
            value={time}
            onChange={handleTimeChange}
            error={!!errors?.time?.message}
            helperText={errors?.time?.message}
            MenuProps={{ PaperProps: { sx: { maxHeight: 260 } } }}
          >
            {timeOptions.map(({ value, disabled }) => (
              <MenuItem
                key={value}
                value={value}
                disabled={disabled}
              >
                {value}
              </MenuItem>
            ))}
          </TimeSelect>

          <RangeBox>
            <RangeBoxTitle>Availability Window</RangeBoxTitle>

            <RangeBoxContent>
              <RangeBoxContentTitle>
                {`Until ${finishTime}`}
              </RangeBoxContentTitle>

              <SliderBox>
                <Slider
                  data-testid="slider"
                  value={interval}
                  min={60}
                  max={180}
                  step={10}
                  valueLabelFormat={valueLabelFormat}
                  marks={selectDateSliderMarks}
                  valueLabelDisplay="on"
                  onChange={(event, newValue) => {
                    setValue('interval', newValue as number);
                  }}
                />
              </SliderBox>
            </RangeBoxContent>
          </RangeBox>
        </Box>
      </CalendarBox>
      <DateOfEventDialog
        isButton={false}
        open={notice}
        onClose={() => setNotice(false)}
        title="Date of The Event"
        description="Please select a date that is at least 2 days from now."
      />

      <StepperButtons
        nextButtonProps={{
          type: 'submit',
        }}
        backButtonProps={{
          onClick: onBack,
        }}
      />
    </DateContainer>
  );
};

SelectDateStep.defaultProps = {
  defaultValues: {
    date: null,
    time: '',
    interval: 60,
  },
};

export default SelectDateStep;
