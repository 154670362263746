import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';

import { CreateTicketRequest, IBuddy, ICourse, IMember, UpdateTicketRequest } from '../types/index';

export const bookTicketApi = createApi({
  reducerPath: 'bookTicketApi',
  baseQuery: buildBaseQuery(''),
  endpoints: (builder) => ({
    getCourses: builder.query<{ data: ICourse[]; total: number }, void>({
      query: () => '/courses',
    }),
    getMembers: builder.query<{ data: IMember[]; total: number }, void>({
      query: () => '/members',
    }),
    getBuddyList: builder.query<{ data: IBuddy[]; total: number }, void>({
      query: () => '/club/members/frequently-used?offset=0&limit=30',
    }),
    createTicket: builder.mutation<void, CreateTicketRequest>({
      query: (body) => ({
        url: '/tickets',
        method: 'POST',
        body,
      }),
    }),
    updateTicket: builder.mutation<void, UpdateTicketRequest>({
      query: ({ id, ...body }) => ({
        url: `/tickets/${id}`,
        method: 'PUT',
        body,
      }),
    }),
  }),

});

export const {
  useGetCoursesQuery,
  useGetMembersQuery,
  useCreateTicketMutation,
  useUpdateTicketMutation,
  useGetBuddyListQuery,
} = bookTicketApi;
