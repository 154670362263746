import { useEffect, useState } from 'react';

import { PRIVATE_PAGES } from '@app/router/pages';
import { useAppSelector } from '@app/store';
import { CounterDoneKeys } from '@modules/auth';

// The ticket Confirmed
export const useDetectUrlsFromEmail = () => {
  const [state, setState] = useState(JSON.parse(JSON.stringify(window.location)));
  const authData = useAppSelector(({ auth }) => auth);

  useEffect(() => {
    if (state.pathname === PRIVATE_PAGES.MY_ACCOUNT) {
      const searchParams = new URLSearchParams(state.search);
      const getIfThereIS = localStorage.getItem(CounterDoneKeys.FROM_EMAIL);

      if (
        !getIfThereIS &&
        !authData.accessToken &&
        !Object.keys(authData.userInfo)?.length &&
        searchParams.has(CounterDoneKeys.TICKET_ID) &&
        searchParams.has(CounterDoneKeys.STATUS)
      ) {
        const ticketId = searchParams.get(CounterDoneKeys.TICKET_ID);
        const status = searchParams.get(CounterDoneKeys.STATUS);
        const data = { ticketId, status };

        localStorage.setItem(CounterDoneKeys.FROM_EMAIL, JSON.stringify(data));
        searchParams.delete(CounterDoneKeys.TICKET_ID);
        searchParams.delete(CounterDoneKeys.STATUS);
      }
    }
  }, [state]);
};
