import React, { FC } from 'react';

import { Close } from '@mui/icons-material';

import {
  SuccessDialogClose,
  SuccessDialogContainer,
  SuccessDialogContent,
  SuccessDialogDescription,
  SuccessDialogTitle,
  SuccessDialogRedirectButton,
} from './styled';

interface SuccessDialogProps {
  open: boolean;
  isButton: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onBackToDatePicker?: () => void;
}

const DateOfEventDialog: FC<SuccessDialogProps> = ({
  open,
  onClose,
  title,
  description,
  isButton,
  onBackToDatePicker,
}) => {
  return (
    <SuccessDialogContainer
      onClose={onClose}
      open={open}
    >
      <SuccessDialogContent>
        <SuccessDialogTitle>{title}</SuccessDialogTitle>
        <SuccessDialogDescription>
          {description}
        </SuccessDialogDescription>
        {!isButton ? <div /> : (
          <SuccessDialogRedirectButton
            type="button"
            variant="contained"
            color="success"
            onClick={(e) => {
              e.preventDefault();

              if (onBackToDatePicker) {
                onBackToDatePicker();
              }
            }}
          >
            Okay
          </SuccessDialogRedirectButton>
        )}
        <SuccessDialogClose
          onClick={onClose}
        >
          <Close fontSize="large" cursor="pointer" />
        </SuccessDialogClose>
      </SuccessDialogContent>
    </SuccessDialogContainer>
  );
};

export default DateOfEventDialog;
