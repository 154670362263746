import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { useAppSelector } from '@app/store';
import { PRIVATE_PAGES } from '@app/router/pages';
import { AuthStorageKeys, CounterDoneKeys } from '@modules/auth';
import { CounterStatus, useMakeTicketCounterApproveOrDeclineMutation } from '@modules/account';

type PropsType = { ticketId: string; status: string }

export const mixMessagesHandler = ({
  status, messageSetter,
}: {
  status: string;
  messageSetter: Dispatch<SetStateAction<any>>;
}) => {
  switch (status) {
    case CounterStatus.INVALID_APPROVED_TICKET:
      messageSetter({
        title: '',
        description: 'The ticket already confirmed',
      });
      break;
    case CounterStatus.APPROVED:
      messageSetter({
        title: 'The ticket Confirmed',
        description: 'The ticket has successfully been confirmed.',
      });
      break;
    case CounterStatus.INVALID_REJECTED_TICKET:
      messageSetter({
        title: '',
        description: 'The ticket already rejected',
      });
      break;
    case CounterStatus.REJECTED:
      messageSetter({
        title: 'The ticket Rejected',
        description: 'The ticket has successfully been rejected.',
      });
      break;
    case 'CANCELED':
      messageSetter({
        title: 'Cancel the Ticket',
        description: 'The ticket has successfully been cancelled.',
      });
      break;
    default:
      messageSetter({ title: 'Something went wrong', description: '' });
  }
};

export const useSmallMessagesLogic = () => {
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);

  const handleShowMessage = ({ title, description }: { title: string; description: string }) => {
    setShowTicketCanceledDialog({ title, description });
  };

  const [
    showTicketCanceledDialog,
    setShowTicketCanceledDialog,
  ] = useState<{ title: string; description: string } | null>(null);

  const [makeTicketCounterApproveOrDecline] = useMakeTicketCounterApproveOrDeclineMutation();

  const handleGetConfirmPopUpFromEmail = async (parsedObjectFromEmail: PropsType) => {
    try {
      await makeTicketCounterApproveOrDecline({ ...parsedObjectFromEmail }).unwrap();

      mixMessagesHandler({
        status: parsedObjectFromEmail.status,
        messageSetter: handleShowMessage,
      });
      navigate(PRIVATE_PAGES.MY_ACCOUNT);
    } catch (error) {
      console.log('Error:', error);

      // @ts-ignore
      const errorStatus = error?.data?.errors?.error as string;

      mixMessagesHandler({
        status: errorStatus || '',
        messageSetter: handleShowMessage,
      });
      navigate(PRIVATE_PAGES.MY_ACCOUNT);
    } finally {
      localStorage.removeItem(CounterDoneKeys.FROM_EMAIL);
    }
  };

  useEffect(() => {
    // works when user came from email then do login and redirect to /my-account and see message about counter offer
    // Added In LocalStorage If After Redirect Data Missing

    const byLocalStorage = localStorage.getItem(CounterDoneKeys.FROM_EMAIL) as string;

    if (!byLocalStorage) {
      return;
    }

    const parsedObjectFromEmail = JSON.parse(byLocalStorage);

    if (Object.keys(parsedObjectFromEmail)?.length) {
      handleGetConfirmPopUpFromEmail(parsedObjectFromEmail);
    }
  }, [auth]);

  useEffect(() => {
    // works when user came from email then just show message
    const searchParams = new URLSearchParams(document.location.search);

    if (!searchParams.has(CounterDoneKeys.TICKET_ID) || !searchParams.has(CounterDoneKeys.STATUS)) {
      return;
    }

    const needOpenConfirmPopup = localStorage.getItem(CounterDoneKeys.FROM_EMAIL) as string;
    const ticketId = searchParams.get(CounterDoneKeys.TICKET_ID);
    const status = searchParams.get(CounterDoneKeys.STATUS);

    if (
      !needOpenConfirmPopup && Object.keys(auth.userInfo)?.length && ticketId && status
    ) {
      handleGetConfirmPopUpFromEmail({ ticketId, status });
    }
  }, [auth]);

  return {
    showTicketCanceledDialog,
    setShowTicketCanceledDialog,
  };
};
