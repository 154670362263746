import { styled, Typography } from '@mui/material';
import COLORS from '@app/colors';

export const InfoBlockText = styled(Typography)`
  font-family: 'Inter', serif;
  font-weight: 400;
  font-size: 13px;
  color: ${COLORS.black};
  word-break: break-all;
`;

export const GreenColor = styled('span')`
  color: ${COLORS.green};
`;
