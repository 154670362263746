/* eslint-disable no-restricted-imports */
import { useEffect } from 'react';

import { Navigate, Outlet, useNavigate } from 'react-router';
import Header from '@components/Header';
import SuspenseLoader from '@components/SuspenseLoader';
import { useAppDispatch, useAppSelector } from '@app/store';
import { PRIVATE_PAGES, PUBLIC_PAGES } from '@app/router/pages';
import { CounterDoneKeys, logout, setUser } from '@modules/auth';
import { useLazyGetProfileQuery } from '@modules/auth/api/auth.api';

import { LayoutContainer } from '@layouts/styled';
import { useDetectUrlsFromEmail } from '@hooks/useDetectUrlsFromEmail';

const AuthLayout = () => {
  const { accessToken, userInfo } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getProfile, { isError }] = useLazyGetProfileQuery();

  useDetectUrlsFromEmail();

  const handleCall = async () => {
    try {
      const userInfoData = await getProfile().unwrap();

      dispatch(setUser(userInfoData.data));
    } catch (error) {
      console.log(error, '----error');
      dispatch(logout());
    }
  };

  useEffect(() => {
    const needOpenConfirmPopup = localStorage.getItem(CounterDoneKeys.FROM_EMAIL) as string;

    if (!needOpenConfirmPopup) {
      return;
    }

    const parsedObjectFromEmail = JSON.parse(needOpenConfirmPopup);

    if (Object.keys(parsedObjectFromEmail)?.length) {
      // that mean user came from email, after click on Confirm Offer button
      navigate(PRIVATE_PAGES.MY_ACCOUNT);
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    handleCall();
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(logout());
    }
  }, [isError]);
  if (!accessToken) {
    return <Navigate to={PUBLIC_PAGES.LOGIN} />;
  }

  if (!userInfo.user) {
    return <SuspenseLoader />;
  }

  return (
    <LayoutContainer>
      <Header />

      <Outlet />
    </LayoutContainer>
  );
};

export default AuthLayout;
