import { useEffect, useState } from 'react';

import Ticket from '@components/Ticket';
import { saveTicketInStorage } from 'modules/book-ticket';
import { PRIVATE_PAGES } from '@app/router/pages';
import { useNavigate } from 'react-router';
import SuccessDialog from '@components/SuccessDialog';
import { createSearchParams } from 'react-router-dom';
import { Pagination, Skeleton } from '@mui/material';
import { PAGINATION_LIMIT } from '@constants/pagination';
import { useAppDispatch, useAppSelector } from '@app/store';
import SeeMoreDialog from '@components/SeeMoreDialog';
import { setSeeMoreTicket, handleOpenTicketsRefetch } from '@modules/ticket';

import { getDateFromFirstGroup } from '@utils/time';
import { convertData } from '@utils/dataConverting';
import { ITicket, TicketStatus, CounterStatus } from '../../types';
import {
  useGetTicketsQuery,
  useChangeTicketStatusMutation,
  useMakeTicketCounterApproveOrDeclineMutation,
} from '../../api/ticket.api';
import useAlert from '@hooks/useAlert';
import { ticketExample } from '../../constants';
import { countOffsetByPage } from '@utils/pagination';
import { mixMessagesHandler } from '../MyAccount/useSmallMessagesLogic';
import { NoTicketsText, PaginationContainer, TicketsBox } from './styled';

const Tickets = () => {
  const { showErrorToast } = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activePage, setActivePage] = useState(1);
  const refetchOpenTickets = useAppSelector((state) => state.additional.refetchOpenTickets);
  const auth = useAppSelector((state) => state.auth);

  const {
    data: ticketsData,
    refetch: refetchTickets,
    isLoading,
  } = useGetTicketsQuery({
    status: TicketStatus.PENDING,
    sortBy: 'DESC',
    orderBy: 'createdAt',
    limit: PAGINATION_LIMIT,
    offset: countOffsetByPage(activePage),
  });

  useEffect(() => {
    if (refetchOpenTickets) {
      refetchTickets();
      dispatch(handleOpenTicketsRefetch({ fetchState: false }));
    }
  }, [refetchOpenTickets]);

  const [changeTicketStatus] = useChangeTicketStatusMutation();
  const [makeTicketCounterApproveOrDecline] = useMakeTicketCounterApproveOrDeclineMutation();

  const [showTicketCanceledDialog, setShowTicketCanceledDialog] = useState<{
    title: string;
    description: string;
  } | null>(null);

  const handleTicketEdit = (ticketInfo: ITicket) => {
    const { groups } = ticketInfo;
    const dateFromFirstGroup = getDateFromFirstGroup(groups);
    const convertedGroups = convertData(groups);

    saveTicketInStorage({
      courses: ticketInfo?.ticketCourses?.map(({ course }) => course) || [],
      groups: convertedGroups,
      comment: ticketInfo.comment,
      date: {
        date: dateFromFirstGroup.toISOString(),
        interval: ticketInfo.availability,
      },
    });

    navigate({
      pathname: PRIVATE_PAGES.BOOK_TICKET,
      search: createSearchParams({
        ticketId: ticketInfo.id,
      }).toString(),
    });
  };

  const handleTicketCancel = async (ticketId: string) => {
    try {
      await changeTicketStatus({
        ticketId,
        status: TicketStatus.CANCELED,
      }).unwrap();

      mixMessagesHandler({
        status: TicketStatus.CANCELED,
        messageSetter: setShowTicketCanceledDialog,
      });

      refetchTickets();
    } catch (e) {
      showErrorToast('Something went wrong, try again later');
    }
  };

  const handleTicketDeclineOrApprove = async ({
    status,
    ticketId,
    date,
    ticketCourseId,
  }: {
    date: string;
    ticketId: string;
    status: CounterStatus;
    ticketCourseId: string;
  }) => {
    try {
      await makeTicketCounterApproveOrDecline({
        status,
        ticketId,
        date,
        ticketCourseId,
      }).unwrap();

      if (status === CounterStatus.APPROVED) {
        mixMessagesHandler({
          status: CounterStatus.APPROVED,
          messageSetter: setShowTicketCanceledDialog,
        });
      }

      if (status === CounterStatus.REJECTED) {
        mixMessagesHandler({
          status: CounterStatus.REJECTED,
          messageSetter: setShowTicketCanceledDialog,
        });
      }

      refetchTickets();
    } catch (e) {
      // @ts-ignore
      const errorStatus = e?.data?.errors?.error as string;

      if (!errorStatus) {
        showErrorToast('Something went wrong, try again later');
      }

      mixMessagesHandler({
        status: errorStatus || '',
        messageSetter: setShowTicketCanceledDialog,
      });
      refetchTickets();
    }
  };

  if (isLoading) {
    return (
      <TicketsBox>
        {[0, 1, 2, 3].map((i) => (
          <Skeleton key={i} animation="wave" variant="rounded">
            <Ticket
              ticket={ticketExample}
              onConfirm={() => {}}
              onReject={() => {}}
              onEdit={() => {}}
              onCancel={() => {}}
            />
          </Skeleton>
        ))}
      </TicketsBox>
    );
  }

  return (
    <>
      {ticketsData?.data.length ? (
        <TicketsBox>
          {ticketsData?.data.map((ticket) => (
            <Ticket
              owner={!!(auth && ticket.creator && auth.userInfo.user.id === ticket.creator.userId)}
              page="openTickets"
              key={ticket.id}
              ticket={ticket}
              onEdit={() => handleTicketEdit(ticket)}
              onCancel={() => handleTicketCancel(ticket.id)}
              onReject={() => {
                handleTicketDeclineOrApprove({
                  ticketCourseId: ticket.id,
                  date: ticket?.date,
                  ticketId: ticket.id,
                  status: CounterStatus.REJECTED,
                });
              }}
              onConfirm={() => {
                handleTicketDeclineOrApprove({
                  ticketCourseId: ticket.id,
                  date: ticket?.date,
                  ticketId: ticket.id,
                  status: CounterStatus.APPROVED,
                });
              }}
            />
          ))}
        </TicketsBox>
      ) : (
        <NoTicketsText>There are no open tickets yet</NoTicketsText>
      )}

      {!!ticketsData?.total && ticketsData.total > PAGINATION_LIMIT && (
        <PaginationContainer>
          <Pagination
            color="success"
            page={activePage}
            count={Math.ceil(ticketsData.total / PAGINATION_LIMIT)}
            onChange={(_, page) => setActivePage(page)}
          />
        </PaginationContainer>
      )}

      <SuccessDialog
        open={!!showTicketCanceledDialog}
        onClose={() => setShowTicketCanceledDialog(null)}
        title={showTicketCanceledDialog?.title || ''}
        description={showTicketCanceledDialog?.description || ''}
      />
    </>
  );
};

export default Tickets;
