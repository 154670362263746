import { Box, styled, Typography } from '@mui/material';
import COLORS from '@app/colors';

export const InfoBlockTextDots = styled(Typography)`
  font-family: 'Inter', serif;
  font-weight: 400;
  font-size: 13px;
  color: ${COLORS.black};
  word-break: break-all;
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
`;

export const MembersWrapper = styled(Box)`
  > span {
    color: rgba(37, 37, 37, 0.50);
    font-family: 'Inter', serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; 
  }
`;
