export enum Steps {
  COURSE = 0,
  DATE = 1,
  // MEMBERS = 2,
  GROUPS = 2,
  COMMENT = 3,
  CONFIRM = 4,
  SUCCESS = 5,
}

export enum CourseStorageKeys {
  ACTIVE_STEP = 'ACTIVE_STEP_STORAGE_DATA',
  COURSE = 'COURSE_STORAGE_DATA',
  DATE = 'DATE_STORAGE_DATA',
  MEMBERS = 'MEMBERS_STORAGE_DATA',
  GROUPS = 'GROUPS_STORAGE_DATA',
  COMMENT = 'COMMENT_STORAGE_DATA',
}
