import { memo } from 'react';

import { ITicket, TicketStatus } from '@modules/account';

import {
  InfoBlockText,
} from './styled';

interface CoursesComponentProps {
  page: string;
  ticket: ITicket;
}

const CoursesComponent = ({ page, ticket }: CoursesComponentProps) => {
  if (page === 'ticketHistory' && ticket.status === TicketStatus.APPROVED) {
    return <InfoBlockText>{ticket?.ticketCourse?.course?.name}</InfoBlockText>;
  }

  if (page === 'openTickets' && ticket.offerStatus === 'PENDING' && ticket?.ticketCourse !== null) {
    return <InfoBlockText key={ticket?.ticketCourse.course.id}>{ticket?.ticketCourse?.course?.name}</InfoBlockText>;
  }

  return (
    <>
      {ticket?.ticketCourses?.map(({ id, course }) => <InfoBlockText key={id}>{course?.name}</InfoBlockText>)}
    </>
  );
};

export default memo(CoursesComponent);
