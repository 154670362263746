import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';

import { IEditProfileFormValues, IEditProfilePasswordFormValues } from '../types';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  keepUnusedDataFor: 0,
  baseQuery: buildBaseQuery(''),
  endpoints: (builder) => ({
    updateProfile: builder.mutation<void, IEditProfileFormValues>({
      query: (body) => ({
        url: '/auth/profile',
        method: 'PUT',
        body,
      }),
    }),
    updatePassword: builder.mutation<void, IEditProfilePasswordFormValues>({
      query: (body) => ({
        url: '/auth/password',
        method: 'PUT',
        body,
      }),
    }),
  }),

});

export const { useUpdateProfileMutation, useUpdatePasswordMutation } = profileApi;
