import { CircularProgress } from '@mui/material';

import { LoaderBox } from './styled';

const SuspenseLoader = () => {
  return (
    <LoaderBox>
      <CircularProgress size={64} disableShrink thickness={3} />
    </LoaderBox>
  );
};

export default SuspenseLoader;
